<template>
  <div class="filter-pane">
    <div :style="{ display: isOpen ? '' : 'flex' }">
      <div class="search-pane" v-if="condition.filterBox" :style="{
          width: isOpen ? '100%' : 'calc(100% - 300px)',
          height: isOpen ? '' : '37px',
        }">
        <span v-for="(item, index) in condition.filterBox" :key="index" style="min-width:25%">
          <span style="display: inline-block; width: 100px; text-align: right">{{ item.conditionName ? item.conditionName + "：" : "" }}</span>
          <template v-if="item.component == 'input'">
            <Input v-model="search_data[item.field]" :placeholder="
                item.conditionName ? `输入${item.conditionName}查询` : ''
              " :style="{ width: `${condition.width}px` }" @on-focus="() => focused(item.isFocus)"></Input>
          </template>
          <template v-if="item.component == 'select'">
            <Select v-model="search_data[item.field]" :style="{ width: `${condition.width}px` }" :filterable="!item.filterable ? false : true" @on-change="(val) => selected(val, item.isCallback, item.mark)">
              <Option v-for="(el, index) in item.data" :value="el[item.parameterField]" :key="index">{{ el[item.showField] }}</Option>
            </Select>
          </template>
          <template v-if="item.component == 'cascader'">
            <Cascader clearable :data="item.data" v-model="search_data[item.field]" :change-on-select="item.isChangeOnSelect ? true : false" :load-data="
                item.isLoadData ? (i, c) => item.loadData(i, c) : undefined
              " :style="{ width: `${condition.width}px` }"></Cascader>
          </template>
          <template v-if="item.component == 'date'">
            <DatePicker :type="item.type" v-model="search_data[item.field]" :style="{ width: `${condition.width}px` }" @on-change="(time)=>getDate(time,item.field)"></DatePicker>
          </template>
          <!-- 拥有快捷选项的日期范围选择器 -->
          <template v-if="item.component == 'dateShortcuts'">
            <DatePicker placeholder="请选择" :type="item.type" v-model="search_data[item.field]" :options="options" :style="{ width: `${condition.width}px` }" @on-change="(time)=>getDate(time,item.field)"></DatePicker>
          </template>
        </span>
        <slot name="other"></slot>
      </div>
      <div v-if="!condition.searchBtnHide && condition.filterBox" class="select-btn" :style="{ width: isOpen ? '100%' : '' }">
        <span class="select-btn-item">
          <span v-if="!condition.searchBtnHide">
            <Button type="primary" icon="md-search" @click="search">查询</Button>
          </span>
          <span v-if="!condition.searchBtnHide">
            <Button type="primary" icon="md-refresh" @click="reset">重置</Button>
          </span>
          <span @click="changeSelectMenu" style="color: #06baa1; cursor: pointer" v-if="!isOpen && isShowOpen">
            展开
            <Icon type="md-arrow-dropdown" />
          </span>
          <span @click="changeSelectMenu" style="color: #06baa1; cursor: pointer" v-if="isOpen && isShowOpen">
            收起
            <Icon type="md-arrow-dropup" />
          </span>
        </span>
      </div>
    </div>
    <div class="btn-group">
      <!-- 导入 -->
      <span v-if="condition.loadModel">
        <Button type="primary" style="margin-right: 10px" icon="md-cloud-download" @click="loadModel">下载模板</Button>
      </span>
      <!-- 导入 -->
      <span v-if="
          (condition.loadEnter ? condition.loadEnter.loadName : false) &&
          (condition.loadEnter.ca ? CA(condition.loadEnter.ca) : true)
        ">
        <Button type="primary" style="margin-right: 10px" icon="ios-log-in" @click="loadEnter">{{ condition.loadEnter.loadName }}</Button>
      </span>
      <!-- 导出 -->
      <span v-if="
          (condition.loadExpend
            ? condition.loadExpend.loadExpendName
            : false) &&
          (condition.loadExpend.ca ? CA(condition.loadExpend.ca) : true)
        ">
        <Button type="primary" style="margin-right: 10px" icon="ios-log-out" @click="loadExpend">{{ condition.loadExpend.loadExpendName }}</Button>
      </span>
      <!-- 新增 -->
      <span v-if="
          (condition.add ? condition.add.addBtnName : false) &&
          (condition.add.ca ? CA(condition.add.ca) : true)
        ">
        <Button type="primary" icon="ios-add" @click="add">{{
          condition.add.addBtnName
        }}</Button>
      </span>
      <!-- 返回 -->
      <span v-if="condition.isBack" style="margin-left: 10px">
        <Button type="primary" icon="ios-arrow-round-back" @click="back">返回</Button>
      </span>
      <!-- 其他 -->
      <span v-if="condition.custom&&condition.custom.filter">
        <template v-for="(item,index) in condition.custom.filter">
          <Poptip confirm transfer :title="item.prompt" @on-ok="handleCustom(item)">
            <Button type="primary" style="margin-right: 10px" :icon="item.icon" v-if="item.ca ? CA(item.ca) : true">{{ item.name }}</Button>
          </Poptip>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'filterPane',
  props: {
    condition: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    let n = {}
    if (this.condition.filterBox) {
      this.condition.filterBox.forEach((item) => {
        n[item.field] = item.defaultValue
        if (
          item.defaultValue != '' &&
          item.defaultValue != [] &&
          item.isCallback
        ) {
          this.selected(item.defaultValue, item.isCallback, item.mark)
        }
      })
    }
    return {
      search_data: n,
      paddingRights: 26,
      isOpen: false,
      isShowOpen: false,
      options: {
        shortcuts: [
          {
            text: '今年',
            value() {
              const end = new Date(new Date().getFullYear() + '-12-31')
              const start = new Date(new Date().getFullYear() + '-01-01')
              return [start, end]
            },
          },
          {
            text: '上一年',
            value() {
              const end = new Date(new Date().getFullYear() - 1 + '-12-31')
              const start = new Date(new Date().getFullYear() - 1 + '-01-01')
              return [start, end]
            },
          },
          {
            text: '下一年',
            value() {
              const end = new Date(new Date().getFullYear() + 1 + '-12-31')
              const start = new Date(new Date().getFullYear() + 1 + '-01-01')
              return [start, end]
            },
          },
        ],
      },
    }
  },
  methods: {
    setDefaults() {
      this.condition.filterBox.forEach((item) => {
        if (item.defaultValue != '' && item.defaultValue != []) {
          this.search_data[item.field] = item.defaultValue
          if (item.isCallback) {
            this.selected(item.defaultValue, item.isCallback, item.mark)
          }
        }
      })
    },
    changeSelectMenu() {
      this.isOpen = !this.isOpen
      if (this.isOpen == true) {
        this.paddingRights = 110
      } else {
        this.paddingRights = 26
      }
    },
    search() {
      this.$emit('search', this.search_data)
    },

    reset() {
      this.condition.filterBox.forEach((item) => {
        this.search_data[item.field] = item.defaultValue
        if (item.isCallback) {
          this.selected(item.defaultValue, item.isCallback, item.mark)
        }
      })
    },
    add() {
      this.$emit('add')
    },
    // 下载模板
    loadModel() {
      let url = this.condition.loadModel.url
      if (!url) {
        this.$Message.warning('下载地址出错')
        return
      }
      let a = document.createElement('a')
      a.target = '_blank'
      a.download = '导入模板'
      a.href = url
      a.click()
    },
    loadEnter() {
      this.$emit('loadEnter')
    },
    loadExpend() {
      this.$emit('loadExpend')
    },
    handleCustom(custom) {
      this.$emit('custom', custom)
    },
    selected(val, isCallback, mark) {
      if (isCallback) {
        this.$emit('sel-cb', val, mark)
      }
    },
    back() {
      this.$router.go(-1)
    },
    focused(isFocus) {
      if (isFocus) {
        this.$emit('foucesed')
      }
    },
    getDate(time, key) {
      this.search_data[key] = time
    },
  },
  watch: {
    // "condition.filterBox"(n) {
    //   console.log(n)
    //   if (n.length > 4) {
    //     this.isShowOpen = true;
    //   } else {
    //     this.isShowOpen = false;
    //   }
    // },
    condition: {
      immediate: true,
      deep: true,
      handler(n) {
        if (n.filterBox) {
          if (n.filterBox.length > 4) {
            this.isShowOpen = true
          } else {
            this.isShowOpen = false
          }
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.filter-pane {
  // display: flex;
  // flex-wrap: nowrap;
  padding: 16px 16px 11px 16px;
  .search-pane {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    > span {
      padding-right: 16px;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
    }
  }
  .select-btn {
    text-align: right;
    // width: 100%;
    .select-btn-item {
      display: inline-block;
      > span {
        padding-right: 16px;
        align-items: center;
      }
    }
  }
  .btn-group {
    margin-top: 10px;
    > span {
      display: inline-block;
      padding-bottom: 5px;
    }
  }
}
</style>