import { post } from "@/common/http"
import API from "@/common/http/api"
import router from "@/router"
export default {
  state: {
    user: null,
    userReady: false
  },
  mutations: {
    setUser(state, info) {
      state.userReady = true;
      state.user = info;
    },
    clearUser(state) {
      state.userReady = false;
      state.user = null;
    }
  },
  actions: {
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        if (localStorage.userId && localStorage.companyNo && localStorage.token) {
          post(API.USER.INFO, {
            id: localStorage.userId
          }).then(res => {
            commit('setUser', res);
            // if (res.version == 0) {
            //   router.push({name: 'informationPerfection'})
            //   reject(true)
            // } else {
              resolve();
            // }
          }).catch(e => reject(e))
        } else {
          reject();
        }
      })
    },
    clearUserInfo({ commit }) {
      return new Promise((resolve) => {
        commit('clearUser')
        resolve()
      })
    }
  },
  modules: {},
}