export default {
    state: {
        // 主题列表
        themeClassList: [
            { className: 'green-theme', color: '#06BAA1' },
            { className: 'blue-theme', color: '#2d8cf0' },
            { className: 'purple-theme', color: '#8d0077' },
        ],
        // 当前主题类名
        themeClass: 'green-theme',
    },
    mutations: {
        // 设置主题下标
        setThemeClass(state, themeClass) {
            state.themeClass = themeClass
        },
    },
    actions: {
        // 更改主题色
        changeTheme({ commit, state }, themeClass) {
            return new Promise((resolve) => {
                if (themeClass) {
                    localStorage.setItem('theme', themeClass)
                    document.body.classList = [themeClass]
                    commit('setThemeClass', themeClass)
                } else if (localStorage.getItem('theme')) {
                    let theme = localStorage.getItem('theme')
                    document.body.classList = [theme];
                    commit('setThemeClass', theme)
                } else {
                    let theme = state.themeClass;
                    document.body.classList = [theme];
                    localStorage.setItem('theme', theme)
                }
                resolve()
            })
        }
    },
    modules: {
    }
}