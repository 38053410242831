import {
  host,
} from './host'

export const baseHost = host

export const baseUrl = {
  URL: process.env.NODE_ENV == 'development' ?
    baseHost + '/api/v1/' : baseHost + '/api/v1/',
}

export const V1 = process.env.NODE_ENV == 'development' ?
  '/api/v1/' :
  baseHost + '/api/v1/';
export const V2 = process.env.NODE_ENV == 'development' ?
  '/openapi/v2/' :
  baseHost + '/openapi/v2/';


const API = {
  // 通用接口
  COMMON: {
    // 天气查询
    WEATHER: 'common/meteorological/weather'
  },
  //导出
  EXPORT: {
    //设备维护
    DEVICE: 'deviceinspectrecord/exportExcel',
    //技术指导巡查
    GUIANCE: 'technicalguidancepatrol/easyExport',
    //粪污处理台账
    LEDGER: 'userfarmers/easyExport',

    
    FARMADMINISTRATE: '/excel/importSolidManureWord',
    DEVICEONE: 'excel/importDeviceInspect',
    GUIANCEONE: 'excel/importPatrol',
  },
  USER: {
    LOGIN: 'login',
    INFO: 'user/view',
    PUBLIC_INFO: 'commonchatmessage/getuser',
    EDIT: 'user/edit',
    GET_CODE: 'getCheckNumber',
    CHECK_CODE: 'checkNumber'
  },
  USER1: {
    EDIT: 'user/updatePwd',

  },
  ROLE: {
    LIST: 'role/list',
    ADD: 'role/add',
    EDIT: 'role/edit',
    DELETE: 'role/delete',
    ROLE_MENU: 'role/menuTreeListByRoleId',
    SET_AUTHORITY: 'role/setAuthority',
    AUTHORITY_SORT: 'role/changesort',
    ROLE_AUTHORITY_LIST: 'sysmenu/rolemenulist'
  },
  //注册
  REGISTER: {
    //验证码
    CODE: 'common/sendsms',
    //短信验证
    CHECKS_CODE: 'common/checksmscode',
    //类型
    PRO: 'product/list',
    //业务范围
    SCOPE: 'syscategory/list',
    //注册
    REGIS: 'register',

    RESET: 'common/reset'
  },
  //菜单
  MENU: {
    LIST: 'sysmenu/listTree',
    ADD: 'sysmenu/add',
    EDIT: 'sysmenu/update',
    AUTHORITY_MENU: 'user/treeMenuList',
    DELETE: 'sysmenu/delete'
  },
  UPLOAD: {
    FILE: 'fileupload/upload'
  },
  MAP: {
    // 获取地图定位等配置信息
    MAP_CFG_INFO: 'common/maplocation/list',
    // 根据省市code码获取边界
    GET_BOUNDARY_BY_CODE: 'common/mappoint/list',
    //地图信息
    MAP_VIEW: 'common/maplocation/info',
    //配置地图信息
    MAP_CONFIG: 'common/maplocation/edit',
  },
  // 开发区配置
  REGION_MAP: {
    ADD: 'regionmap/add',
    EDIT: 'regionmap/update',
    DELETE: 'regionmap/delete',
    LIST: 'regionmap/list',
    STATICS: 'regionmap/statics'
  },
  // 公司
  company: {
    typeList: 'companytype/list',
    typeAdd: 'companytype/add',
    typeEdit: 'companytype/update',
    typeDelete: 'companytype/delete',
    add: 'company/add',
    LIST: 'company/list',
    DELETE: 'company/delete',
    EDIT: 'company/edit',
    BARNCHTREE: 'branch/tree'
  },
  // 注册
  regist: {
    // 申请列表
    LIST: 'check/list',
    // 审核申请,
    AUDIT: 'check/action'
  },
  //车辆信息管理
  CAR_MANAGE: {
    LIST: 'busscar/list',
    DELETE: 'busscar/delete',
    UPDATE: 'busscar/update',
    VIEW: 'busscar/view',
    ADD: 'busscar/add',
    OPEN: 'busscar/open',
    STOP: 'busscar/stop',
    UPLOAD: 'excel/readcar',
    //车辆类型
    TYPE: 'syscategory/list',
  },
  //用户管理
  USER_MANAGE: {
    LIST: 'user/list',
    LISTS: 'user/lists',
    ADD: 'user/add',
    DELETE: 'user/delete',
    EDIT: 'user/edit',
    ALLList: 'user/getalllist',
    CLIST: 'user/childlist',
  },
  //组织管理
  DEPT_MANAGE: {
    LIST: 'branch/list',
    TREE: 'branch/tree',
    ADD: 'branch/add',
    EDIT: 'branch/edit',
    DELETE: 'branch/delete',
    STOP: 'branch/stop',
    OPEN: 'branch/open',
  },
  //产品分类
  PRODUCT_CATEGORY: {
    LIST: 'productcat/list',
    ADD: 'productcat/add',
    UPDATE: 'productcat/update',
    DELETE: 'productcat/delete',
    VIEW: 'productcat/view',
  },
  //司机
  DRIVER_MANAGE: {
    LIST: "userdriver/list",
    ADD: "userdriver/registerdriver",
    UPDATE: "userdriver/update",
    DELETE: "userdriver/delete",
    OPEN: 'userdriver/open',
    STOP: 'userdriver/stop'
  },
  //订单
  ORDER_INFO: {
    LIST: "orderinfo/list",
    ADD: "orderinfo/add",
    UPDATE: "orderinfo/update",
    DELETE: "orderinfo/delete",
    VIEW: "orderinfo/view",
    CONFIRM: "orderinfo/confirm", //订单确认
    FINISH: "orderinfo/finish", //订单完成
    PROCESS: "orderinfo/process", //订单处理
  },
  //产品信息
  PRODUCT_INFO: {
    LIST: 'product/list',
    ADD: 'product/add',
    UPDATE: 'product/update',
    DELETE: 'product/delete',
    VIEW: 'product/view',
  },
  //养殖户
  FAMRMING: {
    ADD: 'userfarmers/add',
    DELETE: 'userfarmers/delete',
    UPDATE: 'userfarmers/update',
    LIST: 'userfarmers/list',
    LEDGER_LIST: 'userfarmers/getUserFarmerLedgerList ',
    VIEW: 'userfarmers/view',
    BASIC_INFORMATION: 'bussfarmerstatics/farmerinfo',
    //导入
    // UPLOAD: 'excel/readfarmer',
    UPLOAD: 'excel/importFarmer',
    // 详情页种植户信息
    INFO: 'userfarmers/allInfo',
    REGION: 'userfarmers/staticsbyregion',
    BASIC_INFORMATION_UPLOAD: 'excel/readfarmerandfarm',
    FACILITY_UPLOAD: 'excel/readfarmfacility',
    RECORD_EXPORT: 'recordreport/reports',
    FARM_INFORMATION_UPLOAD: 'excel/readfarminfomation',
    FARMLIST: 'bussfarm/baselist',
    //企业账号
    COMPANY_SETUSER:'userfarmers/setUser',
    COMPANY_GETUSER:'userfarmers/getUserInfo',
    RESET_PASSWORD:'user/reset',
  },
  //字典
  DICT: {
    LIST: 'dictconfig/list',
    ADD: 'dictconfig/add',
    UPDATE: 'dictconfig/update',
    DELETE: 'dictconfig/delete',
    SYS_LIST: 'dict/list',
  },
  //区域管理
  AREA_MANAGE: {
    //区域列表
    LIST: 'addresarea/list',
    //新增区域
    ADD: 'addresarea/add',
    DELETE: 'addresarea/delete',
    UPDATE: 'addresarea/update',
    //区域树
    CHILDLIST: 'addresarea/childlist',
  },
  //种植户
  PLANT_MANAGE: {
    LIST: 'userplanting/list',
    ADD: 'userplanting/add',
    UPDATE: 'userplanting/update',
    DELETE: 'userplanting/delete',
    INFO: 'userplanting/allInfo',
    //导入
    UPLOAD: 'excel/readplanter',
  },
  //社会化服务组织
  SOCIAL_MANAGE: {
    LIST: 'usersocialservices/list',
    ADD: 'usersocialservices/add',
    UPDATE: 'usersocialservices/update',
    DELETE: 'usersocialservices/delete',
    //导入
    UPLOAD: 'excel/readsocial',
    //详情设备
    FACILITY: 'usersocialservices/allInfo',
  },
  // 皮带秤 beltBalance
  BELT_MANAGE: {
    LIST: 'beltscale/list',
    ECHARTSSTATICLIST: 'beltscale/statics'
  },
  // 设备
  SB_MANAGE: {
    SELECTLIST: 'beltscale/devicelist'
  },
  //基地
  BASE_MANAGE: {
    ADD: 'userfarmers/addUserFarmers',
    LIST: 'bussfarm/list',
    BASELIST: 'bussfarm/baselist',
    UPLOADNEW: 'excel/readBussFarmer',
    UPLOAD:'/bussfarm/exportExcel',
    // 简略信息
    SIMPLE_FARM_LIST: 'bussfarm/baselist',
    UPDATE: 'userfarmers/addUserFarmers',
    DELETE: 'bussfarm/delete',
    VIEW: 'bussfarm/view',
    DEAL_METHOD: 'bussfarmexpand/add',
    INFO: 'bussfarm/info',
    EDIT: 'bussfarm/edit',
    //粪污购买情况
    BUY_WASTE: 'statistics/buywaste',
  },
  //供需关系
  SUPPLY_BIND: {
    ADD: "busssupplydemand/add",
    UPDATE: "busssupplydemand/update",
    DELETE: "busssupplydemand/delete",
    VIEW: "busssupplydemand/view",
    LIST: "busssupplydemand/list",
    //需求处理
    FINISH: "busssupplydemand/finish"
  },
  //生产区
  PRODUCTION_AREA: {
    ADD: 'bussfarmscene/add',
    LIST: 'bussfarmscene/list',
    UPDATE: 'bussfarmscene/update',
    DELETE: 'bussfarmscene/delete',
    SCENE_LIST: 'bussfarmscene/checklist',
    //基地场景
    FARMSCENE: "bussfarm/farmscene",
    //区域 -- 地区列表
    AREA_LIST: 'syscategory/childtree',
  },
  //运输
  TRANSPORT: {
    ADD: "transport/add",
    LIST: "transport/list",
    UPDATE: "transport/update",
    DELETE: "transport/delete",
    VIEW: "transport/view",
    START: "transport/start",
    FINISH: "transport/finish",
    CAR_STATUS: 'transport/carstatus',
    DRIVER_STATUS: 'transport/driverstatus'
  },
  // 鹰眼
  YINGYAN: {
    ADD_POINTS: 'yingyan/track/addpoints',
    LAST_POINT: 'yingyan/track/getlatestpoint',
    TRACK: 'yingyan/track/gettrack',
  },
  //一体机配置
  DEVICES: {
    LIST: "bussdeviceaio/list",
    ADD: "bussdeviceaio/add",
    UPDATE: "bussdeviceaio/update",
    DELETE: "bussdeviceaio/delete",
    OPEN: "bussdeviceaio/open",
    STOP: "bussdeviceaio/stop",
    SBWLIST: 'bussdevicexph/list',
    JYLIST: 'bussdevicejycard/list',
    JYNEW: 'bussdevicejycard/add',
    JYEDIT: 'bussdevicejycard/update',
    JYDEL: 'bussdevicejycard/delete',
    JYOPEN: 'bussdevicejycard/open',
    JYSTOP: 'bussdevicejycard/stop',
  },
  //网关配置
  BUSSDEVICELUOLA: {
    ADD: "bussdeviceluola/add",
    UPDATE: "bussdeviceluola/update",
    LIST: "bussdeviceluola/list",
    DELETE: "bussdeviceluola/delete",
    OPEN: "bussdeviceluola/open",
    STOP: "bussdeviceluola/stop",
    UPDATEGUID: "bussdeviceluola/updateguid"
  },
  //板卡
  BUSSDEVICEXPH: {
    ADD: "bussdevicexph/add",
    LIST: "bussdevicexph/list",
    UPDATE: "bussdevicexph/update",
    DELETE: "bussdevicexph/delete",
    OPEN: "bussdevicexph/open",
    STOP: "bussdevicexph/stop",
  },
  //视频接口
  BUSSDEVICEVIDEO: {
    ADD: "bussdevicevideo/add",
    LIST: "bussdevicevideo/list",
    UPDATE: "bussdevicevideo/update",
    DELETE: "bussdevicevideo/delete",
    OPEN: "bussdevicevideo/open",
    STOP: "bussdevicevideo/stop",
    VIEW: 'bussdevicevideo/view',
    PREVIEW: 'camera/rtspurl'
  },
  // 场景通道相关
  AISLE: {
    // 获取场景通道列表
    AISLE_LIST: 'farmchannel/list',
    // 编辑场景通道
    AISLE_EDIT: 'farmchannel/edit',
    // 新增场景通道
    AISLE_NEW: 'farmchannel/add',
    // 删除场景通道
    AISLE_DEL: 'farmchannel/delete',
    // 聚英板卡通道列表
    JY_AISLELIST: 'bussdevicejydoconfig/list',
    // 聚英板卡通道新增
    JY_AISLENEW: 'bussdevicejydoconfig/add',
    // 聚英板卡通道编辑
    JY_AISLEEDIT: 'bussdevicejydoconfig/update',
    // 聚英板卡通道删除
    JY_AISLEDEL: 'bussdevicejydoconfig/delete',
    // 四八五板卡通道列表
    SBW_AISLELIST: 'bussdevicexphconfig/list',
    // 四八五板卡通道新增
    SBW_AISLENEW: 'bussdevicexphconfig/add',
    // 四八五板卡通道编辑
    SBW_AISLEEDIT: 'bussdevicexphconfig/update',
    // 四八五板卡通道删除
    SBW_AISLEDEL: 'bussdevicexphconfig/delete',
  },
  //传感器
  BUSSDEVICESENSOR: {
    ADD: "bussdevicesensor/add3",
    UPDATE: "bussdevicesensor/update",
    LIST: "bussdevicesensor/list",
    DELETE: "bussdevicesensor/delete",
    DATA_SYNC: 'syncdevicevalue/add'
  },
  // 视频记录设备相关
  RECORD: {
    // 获取视频记录设备列表
    RECORD_LIST: 'farmvideo/list',
    // 添加视频记录设备
    RECORD_NEW: 'farmvideo/add',
    // 编辑视频记录设备
    RECORD_EDIT: 'farmvideo/edit',
    // 视频记录设备详情
    RECORD_DETAIL: 'farmvideo/view',
    // 删除视频记录设备
    RECORD_DEL: 'farmvideo/delete',
    // 更新监控设备状态
    RECORD_Status: 'farmvideo/updatestatus',
    // 视频图片抓拍策略列表
    RECORD_PHOTO_PLOY_LIST: 'videostrategy/list',
    // 新增视频图片抓拍策略
    RECORD_PHOTO_PLOY_NEW: 'videostrategy/add',
    // 编辑视频图片抓拍策略
    RECORD_PHOTO_PLOY_EDIT: 'videostrategy/edit',
    // 删除视频图片抓拍策略
    RECORD_PHOTO_PLOY_DEL: 'videostrategy/delete',
    // 视频图片抓拍策略状态更改
    RECORD_PHOTO_PLOY_STATUS_CHANGE: 'videostrategy/updatestatus',
    // 获取抓拍图片列表
    RECORD_PHOTO_LIST: 'videophoto/list',
    //抓拍图片删除
    RECORD_PHOTO_DELETE: 'videophoto/delete',
    //抓拍图片添加
    RECORD_PHOTO_ADD: 'videophoto/add',
    // 手动抓拍视频图片
    RECORD_PHOTO_HANDLER: 'videophoto/capture',
    // 获取视频预览url
    VIDEO_PREVIEW_URL: 'hkvideo/previewURLs',
    // 视频云平台操作
    VIDEO_CONTROLLING: 'hkvideo/controlling'
  },
  DEVICE_LL: {
    LIST: 'bussdeviceproduct/list',
    // 设备授权
    AUTHORIZATION_LIST: 'bussdeviceproductauth/list',
    AUTHORIZATION_ADD: 'bussdeviceproductauth/add',
    AUTHORIZATION_EDIT: 'bussdeviceproductauth/update',
    AUTHORIZATION_VIEW: 'bussdeviceproductauth/view',
    AUTHORIZATION_DELETE: 'bussdeviceproductauth/delete',
    AUTHORIZATION_BINDING: 'bussdeviceproductauth/authdevice',
  },
  //系统品类
  SYS_CATEGORY: {
    LIST: 'syscategory/list',
    LIST_TREE: 'syscategory/listgoods',
    CHILD_TREE: 'syscategory/childtree',
    ADD: 'syscategory/add',
    EDIT: 'syscategory/edit',
    DELETE: 'syscategory/delete',
    VIEW: 'syscategory/view/{id}'
  },
  //存栏
  LIVESTOCK_ON_HAND: {
    LIST: 'busssceneexpand/list',
    ADD: 'busssceneexpand/add',
    ADD_RECORD: 'busssceneexpandrecord/add',
    RECORD_LIST: 'busssceneexpandrecord/list',
    EDIT: 'busssceneexpand/update',
    DELETE: 'busssceneexpand/delete',
    STOP: 'busssceneexpand/stop',
    //存栏上报
    REPORT: 'statistics/areaInventory',
  },
  //粪污处理设备
  DEAL_DEVICE: {
    LIST: 'bussdeviceinfo/list',
    ADD: 'bussdeviceinfo/add',
    EDIT: 'bussdeviceinfo/update',
    DELETE: 'bussdeviceinfo/delete',
    VIEW: 'bussdeviceinfo/view',
    UPLOAD: 'excel/readdevice',
    UPLOAD2: 'excel/readprocessing',

  },
  DEAL_FACILITIES: {
    LIST: 'bussfacilityinfo/list',
    ADD: 'bussfacilityinfo/add',
    EDIT: 'bussfacilityinfo/update',
    DELETE: 'bussfacilityinfo/delete',
    VIEW: 'bussfacilityinfo/view',
    //导入
    UPLOAD: 'excel/readfacility',
  },
  //图片配置
  PICTURE_CONFIG: {
    LIST: 'adv/list',
    ADD: 'adv/add',
    EDIT: 'adv/update',
    DELETE: 'adv/delete',
    INSERT: 'adv/insert'
  },
  //季节配置
  SEASON_CONFIG: {
    EDIT: 'manurewaterseason/update',
    LIST: 'manurewaterseason/list'
  },
  USER_PRODUCT_CATEGORY: {
    LIST: 'productcategory/list',
    ADD: 'productcategory/add',
    EDIT: 'productcategory/update',
    DELETE: 'productcategory/delete'
  },
  USER_PRODUCT: {
    LIST: 'productexpand/list',
    ADD: 'productexpand/add',
    EDIT: 'productexpand/update',
    DELETE: 'productexpand/delete'
  },
  //粪污公式
  FORMULA: {
    ADD: 'manurewaterformula/save',
    LIST: 'manurewaterformula/list'
  },
  //元素管理
  ELEMENT: {
    ADD: 'manureelement/save',
    LIST: 'manureelement/list'
  },
  //作物元素
  CROP_ELEMENT: {
    ADD: 'manureelementplantstandard/save',
    LIST: 'manureelementplantstandard/list'
  },
  //规模配置
  SCALE_MANAGE: {
    ADD: 'bussfarmingscale/add',
    LIST: 'bussfarmingscale/list',
    UPDATE: 'bussfarmingscale/update',
    DELETE: 'bussfarmingscale/delete',
  },
  //状态
  SPORT_STATUS: {
    DRIVERS: 'transport/driverstatus',
    CARS: 'transport/carstatus',
  },
  //日志
  LOG_MANAGE: {
    //登录日志
    LOGIN: 'log/login',
    //操作日志
    OPERATION: 'log/operation',
  },
  //地力等级
  AREA_LEVEL: {
    ADD: 'manureplotlevel/save',
    LIST: 'manureplotlevel/list'
  },
  //施用设备
  USE_DEVICE: {
    LIST: 'bussusedevice/list',
    ADD: 'bussusedevice/add',
    EDIT: 'bussusedevice/update',
    DELETE: 'bussusedevice/delete'
  },
  //粪污生产模型
  MANURE_PRODUCTION_MODEL: {
    VIEW: 'manuremodel/view/{id}',
    PRODUCTION: 'model/todaymanureproduction',
    LIST: 'manuremodel/list',
    // 更新牲畜平均重量
    AVG_WEIGHT: "bussfarmscene/updateAvgWeight"
  },
  //粪污排水量模型
  MANURE_DRAIN_MODEL: {
    VIEW: 'model/todaywater',
    DRAIN: 'model/todaywater',
    LIST: 'manurewatermodel/list'
  },
  //粪污处理监测
  MANUER_DEAL_MONITOR: {
    LIST: 'dealwasterecord/list',
    ADD: 'dealwasterecord/add',
    EDIT: 'dealwasterecord/update',
    DELETE: 'dealwasterecord/delete'
  },
  //土壤承载力模型
  SOIL_CARRYING_CAPACITY_MODEL: {
    CARRYING_CAPACITY: 'model/todaycapacity',
    LIST: 'model/capacitylist'
  },
  //粪污施用记录
  WASTE_APPLICATION: {
    LIST: 'manureapplyrecord/list',
    ADD: 'manureapplyrecord/add',
    UPDATE: 'manureapplyrecord/update',
    DELETE: 'manureapplyrecord/delete',
    VIEW: 'manureapplyrecord/view',
    // 种植户施用
    PLANT_ADD: 'plantingApplyRecord/add',
    PLANT_LIST: 'plantingApplyRecord/list'
  },
  //粪污利用记录
  WASTE_USING: {
    LIST: 'manureuserecord/list',
    ADD: 'manureuserecord/add',
    VIEW: 'manureuserecord/view',
  },
  //编码类型管理
  CODE_CAT: {
    LIST: 'codecat/list',
    UPDATE: 'codecat/update',
    DELETE: 'codecat/delete',
    VIEW: 'codecat/view',
  },
  //编码
  CODE: {
    LIST: 'code/list',
    UPDATE: 'code/update',
    DELETE: 'code/delete',
    VIEW: 'code/view',
  },
  // 聊天
  TALK: {
    // 会话列表
    LIST: 'commonchatmessage/list',
    // 添加历史消息
    HISTORY_ADD: 'commonchatmessage/add',
    // 查看历史消息
    HISTORY_LIST: 'commonchatmessage/list',
  },
  //图例
  LEGEND: {
    DEVICE_LEGEND: 'bussdeviceproductauth/statistics'
  },
  //监测
  MONITOR: {
    // 传感器统计图表
    MONITOR_CHART: 'devicehour/listhour',
    // 获取监测传感器列表(分组树形)
    // MONITOR_TREE: 'farmsensor/groupcategory/list',
    MONITOR_TREE: 'bussdevicesensor/groupcategory/list',
    MONITOR_CHART_NEW: 'devicehour/listshow',
    //处理效果监测
    TREATMENT_EFFECT_MONITOR: 'bussdevicesensor/grouplist'
  },
  //预警策略
  WARNING_MANAGE: {
    LIST: 'categoryrange/list',
    ADD: 'categoryrange/add',
    UPDATE: 'categoryrange/update',
    DELETE: 'categoryrange/delete',
  },
  //阈值警告
  THRESHOLD_MANAGE: {
    ADD: 'threshold/add',
    LIST: 'threshold/list',
    UPDATE: 'threshold/update',
    DELETE: 'threshold/delete',
  },
  //一张图统计
  STATICTICS_MAP: {
    //利用一张图统计
    USEING: 'statistics/liyong',
    FWYZT: 'statistics/fwyzt',
    PLATFORM_INFO: 'normvalue/getresource2',
    //全国畜禽粪污收纳总量
    AMOUNT: 'statistics/manureStorageAmountByRegion',
    //秸秆收纳总量
    MUSCLE: 'statistics/strawStorageAmountByRegion',
  },
  STATICTICS_ANALYSIS: {
    //转运统计
    TRANSPORT_CAR_STATUS: 'transport/databoard/car',
    TRANSPORT_DRIVER_STATUS: 'transport/databoard/driver',
    TRANSPORT_DRIVER_RANK: 'transport/databoard/driverrank',
    TRANSPORT_CAR_RANK: 'transport/databoard/carrank',
    TRANSPORT_TRANSFER_ORDER: 'transport/databoard/transport',
    //转运量时间轴和列表
    TRANSPORT_STATICS_RIGION: 'statistics/transportManureByRegion',

    //社会化服务组织统计
    SERVER_COMPANY: 'usersocialservices/databoard/servicecount',
    SERVER_DEMAND: 'usersocialservices/databoard/demandcount',
    SERVER_SUPPLY: 'usersocialservices/databoard/supplycount',
    SERVER_TRANSPORT: 'usersocialservices/databoard/transportcount',
    S_A_D_CHANGE: 'usersocialservices/databoard/demandchange',

    //养殖户统计
    FARMING_STAT: 'bussfarm/statistics',

    //种植统计看板
    PLANT_FERTILIZATION_CHANGE: 'dealwasterecord/statics'
  },
  //指标
  MONITORINGINDICATOR: {
    ADD: 'monitoringindicator/add',
    LIST: 'monitoringindicator/list',
    UPDATE: 'monitoringindicator/update',
    DELETE: 'monitoringindicator/delete',
    GROUP: 'monitoringindicator/grouplist',
  },
  //质量检测
  QUALITY_DETECTION: {
    ADD: 'monitoringindicatorrecord/add',
    LIST: 'monitoringindicatorrecord/list',
    UPDATE: 'monitoringindicatorrecord/update',
    DELETE: 'monitoringindicatorrecord/delete',
  },
  //生产一张图
  PRODUCE_MAPINFO: {
    STATICS: 'statistics/shengchan',
    //预警
    WARNING: 'farmalarm/list',
    // 粪污生产量
    PRODUCE: 'model/manurebydate',
    PRODUCES: 'normvalue/getresource2'
  },
  //分类指标
  CLASSFICATION: {
    ADD: 'normdimension/add',
    UPDATE: 'normdimension/update',
    DELETE: 'normdimension/delete',
    TREE: 'normdimension/listtree',
  },
  //统计管理
  STATICS_MANAGE: {
    ADD: 'normvalue/add',
    LIST: 'normvalue/list',
    UPDATE: 'normvalue/update',
    DELETE: 'normvalue/delete',
    GET2: 'normvalue/getresource2',
    GET3: 'normvalue/getresource3',
    GET4: 'normvalue/getliyong',
    GET_ZHAUNYUN: 'statistics/manureorderyear',
    GET_ZHAUNYUNS: 'normvalue/getresource2'
  },
  //消息
  MSG: {
    LIST: 'commonmessage/list',
    VIEW: 'commonmessage/view',
    DELETE: 'commonmessage/delete'
  },
  //车辆维修记录
  CAR_MAINTENANCE: {
    LIST: "busscarrepair/list",
    DELETE: "busscarrepair/delete",
    VIEW: "busscarrepair/view",
    ADD: "busscarrepair/add",
  },
  //养殖场图纸
  FARM_BLUEPRINT: {
    LIST: 'drawing/list',
    ADD: "drawing/add",
    DEL: "drawing/delete",
    SERCH: 'drawing/view'
  },
  //统计分析报表
  STATISTICS_ANALYSE: {
    //粪污处理点个数
    DISPOSE_NUM: 'statistics/manureProcessPointWithCompany',
    //设备类型数
    TYPE_NUM: 'statistics/deviceTypeCountWithCompany',
    //设备数
    FACILITY_NUM: 'statistics/deviceCountWithCompany',
    // 设备处理量
    FACILITY_DISPOSE_NUM: 'statistics/deviceProcessAmountWithCompany',
    // 粪污施用量
    USE_AMOUNT: 'statistics/manureApplyAmountWithCompany',
    // 综合统计
    GROSS: 'statistics/comprehensiveStatisticsWithCompany',

  },
  WATER_USAGE: {
    ADD: 'waterUsage/report',
    LIST: "waterUsage/list",
    TOTAL: 'model/waterUsageByDate'
  },
  //公司管理
  FIRM_ADMINISTRATOR: {
    LIST: 'company/view'
  },
  //粪污生产管理-统计分析
  FARM_STATISTICS: {
    //设备设施
    FACILITY: "statistics/deviceFacilityCount",
    //存栏数
    BREEDING: "statistics/farmExpandFarmerCount",
    //粪污生产
    FECESPD: 'model/manurebydate',
    //粪污转运
    TRANSPORT: 'statistics/transportManureByRegion',
    //粪污施用
    FECESUSE: 'statistics/manureApplyAmountWithCompany',
    //当日数据
    DAYLIST: 'model/todayManureProductionByFarm'
  },
  //销售上报
  SELL_USAGE: {
    ADD: "productionsale/add",
    LIST: 'productionsale/list',
    EDIT: 'productionsale/update',
    DEL: 'productionsale/delete',
  },
  //基地附属信息
  FARMINFORMATION: {
    LIST: 'farminformation/list',
    ADD: 'farminformation/add',
    UPDATE: 'farminformation/update',
    DELETE: 'farminformation/delete',
  },
  //备案信息上报
  REPORT_RECORD: {
    ADD: 'recordreport/add',
    LIST: 'recordreport/list',
    UPDATE: 'recordreport/update',
    DELETE: 'recordreport/delete',
  },
  //统计分析报表
  TOTAL_TABE: {
    //粪污收纳量
    FECES_ACCEPT: "statistics/manureStorageAmountByRegion",
    //秸秆收纳量
    STRAW: "statistics/strawStorageAmountByRegion",
    //销售量
    SELL: "statistics/productionSaleByRegion",
    PROCESSING_CAPCITY: 'statistics/monitorCountByRegion',
    PROCESSING_CAPCITY_CHAT: 'statistics/monitorTimeLineByRegion'
  },
  //液态肥统计
  LIQUID_MANURE: {
    LIST: 'statistics/liquidFertilizerByRegion'
  },
  //存栏量统计
  AMOUNT_TOTAL: {
    LIST: 'statistics/inventoryByRegion'
  },
  //排污量统计
  DISCHARGE_TOTAL: {
    LIST: "statistics/waterByRegion",
  },
  //粪污产量统计
  FECES_TOTAL: {
    LIST: 'statistics/manureByRegion',
  },
  //轨迹
  TRAJECTORY: 'jimi/track',
  //养殖场管理
  FARM_ADMINISTRATE: {
    LIST: 'solidmanureutilize/list',
    DELETE: 'solidmanureutilize/delete',
    ADD: 'solidmanureutilize/add',
    UPDATE: 'solidmanureutilize/update',
    INFO: 'solidmanureutilize/details',
    EXPORT: 'solidmanureutilize/easyExport',
    MANURE_EXPORT: 'solidmanureutilize/manureExport',
    CONFIRM:'solidmanureutilize/updateStatus',
  },
  //设备设施维护
  DEVICE_RECORD: {
    LIST: 'deviceinspectrecord/list',
    DELETE: 'deviceinspectrecord/delete',
    ADD: 'deviceinspectrecord/add',
    UPDATE: 'deviceinspectrecord/update',
    INFO: 'deviceinspectrecord/details',
  },
  //设备设施维护记录
  DEVICE_RECORD_LOG: {
    LIST: 'devicemaintenancerecord/list',
    DELETE: 'devicemaintenancerecord/delete',
    ADD: 'devicemaintenancerecord/add',
    UPDATE: 'devicemaintenancerecord/update',
    INFO: 'devicemaintenancerecord/details',
  },
  //技术指导巡查
  INSPECTION_RECORDS: {
    LIST: 'technicalguidancepatrol/list',
    DELETE: 'technicalguidancepatrol/delete',
    ADD: 'technicalguidancepatrol/add',
    UPDATE: 'technicalguidancepatrol/update',
    INFO: 'technicalguidancepatrol/details',
    REPLY: 'technicalguidancepatrol/replyUpdate',
  },
  //用户区域管理
  USER_AREA_MANAGE: {
    LIST: 'userareacompany/list',
    DELETE: 'userareacompany/delete',
    ADD: 'userareacompany/add',
    UPDATE: 'userareacompany/update',
    INFO: 'userareacompany/details',
  },
  //监管机构
  REGULATORY: {
    LIST: 'usersocialservices/list',
    DELETE: 'usersocialservices/delete',
    ADD: 'usersocialservices/addNW',
    UPDATE: 'usersocialservices/update',
    INFO: 'usersocialservices/details',
  }
}
export default API;
