<template>
  <div id="app">
    <router-view />
    <talk-modal></talk-modal>
    <picture-view
      :value="previewImageStatus"
      :src="previewImageUrl"
      @input="dealImageChange"
    ></picture-view>
  </div>
</template>
<script>
import TalkModal from "@/components/talkModal";
import pictureView from "@/components/picture_view";
import { mapState } from "vuex";
export default {
  components: {
    TalkModal,
    pictureView,
  },
  computed: {
    ...mapState({
      previewImageUrl: (state) => state.rongIMLib.previewImageUrl,
      previewImageStatus: (state) => state.rongIMLib.previewImageStatus,
    }),
  },
  methods: {
    dealImageChange(value) {
      if (value) return;
      this.$store.dispatch("closePreviewImage");
    },
  },
};
</script>

<style lang="less">
#app {
  height: 100vh;
  width: 100vw;
  // min-height: 930px;
  // min-width: 1920px;
}
</style>
