<template>
  <div>
    <base-upload
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :format="['jpg', 'jpeg', 'png']"
      ref="base"
      @input="
        (val) => {
          $emit('input', val);
        }
      "
      :value="value"
      :maxSize="maxSize"
      :max="max"
      :url="url"
      :otherData="{ folder: 'images' }"
      :class="[selfClass ? selfClass : '']"
      :style="selfStyle ? selfStyle : {}"
    >
      <template v-slot:default="{ uploadList }">
        <div
          class="demo-upload-list"
          :key="index"
          v-for="(item, index) in uploadList"
        >
          <template v-if="item.status === 'finished'">
            <img :src="item.url" />
            <div class="demo-upload-list-cover">
              <Icon
                type="ios-eye-outline"
                @click.native="handleView(item.url)"
              ></Icon>
              <Icon
                v-if="!readonly"
                type="ios-trash-outline"
                @click.native="handleRemove(item)"
              ></Icon>
            </div>
          </template>
          <template v-else>
            <Progress
              v-if="item.showProgress"
              :percent="item.percentage"
              hide-info
            ></Progress>
          </template>
        </div>
      </template>
      <template #button>
        <div v-show="!readonly" class="uploadButton">
          <slot name="placeholder">
            <Icon type="md-add" size="20"></Icon>
            <span>图片上传</span>
          </slot>
        </div>
      </template>
    </base-upload>
    <picture-view :src="src" v-model="visible"></picture-view>
  </div>
</template>
<script>
import pictureView from "../picture_view";
import API, { V1 } from "@/common/http/api";
import baseUpload from "./base_upload";

function dataURItoBlob(base64Data) {
  let byteString;
  if (base64Data.split(",")[0].indexOf("base64") >= 0)
    byteString = window.atob(base64Data.split(",")[1]);
  else byteString = window.decodeURI(base64Data.split(",")[1]);
  let mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export default {
  components: {
    pictureView,
    baseUpload,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    selfClass: String,
    selfStyle: Object,
    maxSize: Number,
    max: Number,
    // 清空标识符,变为true 就清空一次
    clearFlag: [Number, String, Boolean, Object, Array],
    readonly: Boolean,
  },
  data() {
    return {
      src: "",
      visible: false,
      url: V1 + API.UPLOAD.FILE,
    };
  },
  watch: {
    clearFlag(flag) {
      flag && this.clearFiles();
    },
  },
  methods: {
    clearFiles() {
      this.$refs.base.clearFiles();
    },
    handleView(url) {
      this.src = url;
      this.visible = true;
    },
    handleRemove(file) {
      this.$refs.base.handleRemove(file);
    },
    handleFormatError() {
      this.$Message.warning("请选择图片上传!");
    },
    handleMaxSize() {
      this.$Message.warning(`图片不要超过${this.maxSize}kb`);
    },
    handleBeforeUpload() {
      if (this.max) {
        const check = this.uploadList.length < this.max;
        if (!check) {
          this.$Message.warning({
            title: "最多上传" + this.max + "张图片",
          });
          return false;
        }
      }

      if (this.maxSize && file.size > 1024 * 1024 * this.maxSize) {
        let URL = window.URL || window.webkitURL;
        let objecturl = URL.createObjectURL(file);
        let img = document.createElement("img");
        img.src = objecturl;
        let _self = this;
        return new Promise((resolve, reject) => {
          img.onload = function () {
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            let scale = ((1024 * 1024 * _self.maxSize) / file.size).toFixed(6);
            let canvas = document.createElement("canvas");
            canvas.width = width * scale;
            canvas.height = height * scale;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(
              img,
              0,
              0,
              width,
              height,
              0,
              0,
              width * scale,
              height * scale
            );
            let image_url = canvas.toDataURL(file.type, 1);
            let blob = dataURItoBlob(image_url);
            let newFile = new File([blob], file.name, { type: file.type });
            resolve(newFile);
          };
        });
      } else return true;
    },
  },
};
</script>
<style>
.demo-upload-list {
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
<style lang="less" scoped>
.uploadButton {
  width: 80px;
  height: 80px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.uploadContainer {
  display: flex;
}
</style>
