import * as RongIMLib from '@rongcloud/imlib-v4'
import { RYAPPKEY } from '@/common/constants'
import { post } from "@/common/http/index"
import API from "@/common/http/api"
import { Message } from 'view-design'
import moment from 'moment'

export default {
    state: {
        // 当前预览的图片地址
        previewImageUrl: '',
        // 预览状态
        previewImageStatus: false,
        // 融云连接实例
        im: null,
        // 融云登陆状态
        imStatus: false,
        // 展示的会话列表
        talkList: [],
        // 聊天窗口是否弹出
        show: false,

        // 当前会话
        currentTalk: null,
        // 会话记录缓存
        currentTalkInfoMap: new Map(),
        // 当前会话记录
        currentTalkInfo: [],
        // 新消息数量
        newMessageNumber: 0,
    },
    mutations: {
        // 设置融云实例
        setIm(state, instance) {
            state.im = instance
        },
        // 融云连接状态
        setImStatus(state, status) {
            state.imStatus = status
        },
        // 设置初始会话列表
        setTalkList(state, list) {
            let num = 0
            list.forEach(item => {
                num += item.unreadNum
            })
            state.newMessageNumber = num
            state.talkList = list
        },
        clearTalkList(state) {
            state.talkList = []
        },
        // 更改窗口弹出状态
        setShow(state, status) {
            state.show = status
        },
        setTalktotal(state, { talk, total }) {
            let index = state.talkList.findIndex(item => item.userId == talk.userId)
            if (index !== -1) {
                state.talkList.splice(index, 1, { ...talk, total: total })
            }
        },
        // 更改会话总计聊天数量
        setCheckNum(state, { talk, total }) {
            let index = state.talkList.findIndex(item => item.userId == talk.userId)
            if (index !== -1) {
                state.talkList.splice(index, 1, { ...talk, checkNum: total })
            }
        },
        // 更新会话未读数量
        clearUnreadNum(state, talk) {
            let index = state.talkList.findIndex(item => item.userId == talk.userId)
            if (index !== -1) {
                state.newMessageNumber -= talk.unreadNum
                state.talkList.splice(index, 1, { ...talk, unreadNum: 0 })
            }
        },
        // 更改当前会话
        setCurentTalk(state, talk) {
            state.currentTalk = talk
        },
        // 设置当前会话记录
        setCurrentTalkInfo(state, list) {
            state.currentTalkInfo = list
        },
        // 设置会话缓存
        setCurrentTalkInfoMap(state, payload) {
            let newList, userId = payload.userId, info = payload.info, position = payload.position || 'new';
            let oldList = state.currentTalkInfoMap.get(userId) || []
            if (position == 'new') {
                newList = [...oldList, ...info]
            }
            state.currentTalkInfoMap.set(userId, newList)
        }
    },
    actions: {
        // 预览图片
        previewImage({ state }, url) {
            state.previewImageUrl = url;
            state.previewImageStatus = true;
        },
        // 关闭预览
        closePreviewImage({ state }) {
            state.previewImageStatus = false
        },
        // 发送文本消息
        sendTextMessage({ state, dispatch }, { targetId, content, userId }) {
            if (!state.im || !state.imStatus) {
                Message.warning('连接服务器失败')
                return
            }
            let conversation = state.im.Conversation.get({
                targetId,
                type: RongIMLib.CONVERSATION_TYPE.PRIVATE
            })
            return new Promise(resolve => {
                conversation.send({
                    messageType: RongIMLib.MESSAGE_TYPE.TEXT,
                    content: { content }
                }).then(() => {
                    post(API.TALK.HISTORY_ADD, {
                        cUserId: userId,
                        rUserId: targetId,
                        content: JSON.stringify({ content, type: 'message' })
                    })
                    dispatch('pushNewMessage', { targetId, userId, content: { content, type: 'message' } })
                    resolve()
                })
            })
        },
        // 发送图片信息
        sendImageMessage({ state, dispatch }, { userId, targetId, base64, url }) {
            if (!state.im || !state.imStatus) {
                Message.warning('连接服务器失败')
                return
            }
            let conversation = state.im.Conversation.get({
                targetId,
                type: RongIMLib.CONVERSATION_TYPE.PRIVATE
            })
            return new Promise(resolve => {
                conversation.send({
                    messageType: RongIMLib.MESSAGE_TYPE.IMAGE,
                    content: {
                        content: base64,
                        imageUri: url,
                    }
                }).then(() => {
                    post(API.TALK.HISTORY_ADD, {
                        cUserId: userId,
                        rUserId: targetId,
                        content: JSON.stringify({ content: url, scale: base64, type: 'image' })
                    })
                    dispatch('pushNewMessage', { targetId, userId, content: { content: url, scale: base64, type: 'image' } })
                    resolve()
                })
            }).catch(e => {
                console.log(e)
            })
        },
        // 会话列表推送新的消息
        pushNewMessage({ state, commit }, { targetId, userId, content }) {
            let talk = state.talkList.find(item => item.userId == targetId)
            if (!talk) return;
            commit('setCheckNum', { talk, total: talk.checkNum + 1 })
            let info = state.currentTalkInfoMap.get(+targetId) || []
            info.push({
                createTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                content,
                cUserId: userId,
                id: new Date().getTime().toString()
            })
            state.currentTalkInfoMap.set(targetId, info)
            if (state.currentTalk.userId == targetId) state.currentTalkInfo = info
        },
        // 查询初始会话列表
        getTalkList({ commit }) {
            post(API.TALK.LIST).then(res => {
                let list = res.list.map(item => ({
                    //头像
                    avatar: item.avatar ?
                        item.severPath + item.avatar :
                        'http://img.xd.sidwit.com:80/image/fwpt/4c09700551ac452b975d65bfd318c4d1.jpg',
                    //已加载信息数量
                    checkNum: 0,
                    // 总计聊天数量
                    totalNum: 0,
                    //未读信息数量
                    unreadNum: item.chartNum,
                    // 对方用户id
                    userId: item.cUserId,
                    // 对方用户名称
                    userName: item.userName,
                    // 对方用户角色
                    roleId: item.roleId,
                    // 对方角色名
                    roleName: item.roleIdName
                }))
                commit('setTalkList', list)
            })
        },
        // 关闭会话窗口
        closeTalkModal({ commit }) {
            commit('setShow', false)
            commit('setCurentTalk', null)
            commit('setCurrentTalkInfo', [])
        },
        // 发起会话
        startTalk({ state, commit, dispatch }, userId) {
            if (state.talkList.length == 0 && !userId) return
            commit('setShow', true)
            if (!userId) {
                if (state.currentTalk) return;
                dispatch('changeTalk', state.talkList[0])
            } else {
                let index = state.talkList.findIndex(item => item.userId == userId)
                if (index !== -1) {
                    dispatch('changeTalk', state.talkList[index])
                } else {
                    post(API.USER.PUBLIC_INFO, {
                        rUserId: userId
                    }).then(item => {
                        let talk = {
                            //头像
                            avatar: item.avatar ?
                                item.severPath + item.avatar :
                                'http://img.xd.sidwit.com:80/image/fwpt/4c09700551ac452b975d65bfd318c4d1.jpg',
                            //已加载信息数量
                            checkNum: 0,
                            // 总计信息数量
                            totalNum: 0,
                            //未读信息数量
                            unreadNum: 0,
                            // 对方用户id
                            userId: item.userId,
                            // 对方用户名称
                            userName: item.userName,
                            // 对方用户角色
                            roleId: item.roleId,
                            // 对方角色名
                            roleName: item.roleIdName
                        }
                        state.talkList.unshift(talk)
                        dispatch('changeTalk', talk)
                    })
                }
            }
        },
        // 切换会话
        changeTalk({ commit, state, dispatch }, talk) {
            if (state.currentTalk && state.currentTalk.userId == talk.userId) return;
            let index = state.talkList.findIndex(item => item.userId == talk.userId)
            if (index !== -1) {
                commit('clearUnreadNum', state.talkList[index])
                commit('setCurentTalk', state.talkList[index])
                return dispatch('getHistoryInfo', state.talkList[index])
            }
        },
        // 设置历史消息记录
        getHistoryInfo({ state, commit }, talk) {
            let tempInfo = state.currentTalkInfoMap.get(talk.userId)
            if (tempInfo) {
                commit('setCurrentTalkInfo', tempInfo)
                return;
            }
            commit('setCurrentTalkInfo', [])
            commit('setCurrentTalkInfoMap', { userId: talk.userId, info: [] })
            // post(API.TALK.HISTORY_LIST, {
            //     cUserId: talk.userId,
            //     pageNo: 1,
            //     pageSize: 30
            // }).then(res => {
            //     let info = res.list.reverse().map(item => {
            //         let result = {
            //             ...item
            //         }
            //         try {
            //             result.content = JSON.parse(item.content)
            //         } catch {
            //             result.content = item.content
            //         }
            //         return result
            //     })
            //     commit('setCheckNum', { talk, total: info.length })
            //     commit('setTalktotal', { talk, total: +res.total })
            //     commit('setCurrentTalkInfoMap', { userId: talk.userId, info })
            //     commit('setCurrentTalkInfo', info)
            // })
        },
        // 组建历史信息(后台处理，不切换当前对话)
        getSpecialHistoryInfo({ state, commit }, talk, message) {
            let tempInfo = state.currentTalkInfoMap.get(talk.userId)
            if (tempInfo) {
                // 接受文本消息
                if (message.messageType === RongIMLib.MESSAGE_TYPE.TEXT) {
                    tempInfo.push({
                        avatar: talk.avatar,
                        cUserId: talk.userId,
                        content: { content: message.content.content, type: 'message' },
                        createTime: moment(message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                        createUserId: talk.userId,
                        id: message.receivedTime,
                        rUserId: rootState.user.user.id,
                        userName: talk.userName
                    })
                }
                // 接受图片消息
                if (message.messageType === RongIMLib.MESSAGE_TYPE.IMAGE) {
                    tempInfo.push({
                        avatar: talk.avatar,
                        cUserId: talk.userId,
                        content: { type: 'image', content: message.content.imageUri, scale: message.content.content },
                        createTime: moment(message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                        createUserId: talk.userId,
                        id: message.receivedTime,
                        rUserId: rootState.user.user.id,
                        userName: talk.userName
                    })
                }
                return;
            } else {
                let newInfo = []
                // 接受文本消息
                if (message.messageType === RongIMLib.MESSAGE_TYPE.TEXT) {
                    newInfo.push({
                        avatar: talk.avatar,
                        cUserId: talk.userId,
                        content: { content: message.content.content, type: 'message' },
                        createTime: moment(message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                        createUserId: talk.userId,
                        id: message.receivedTime,
                        rUserId: rootState.user.user.id,
                        userName: talk.userName
                    })
                }
                // 接受图片消息
                if (message.messageType === RongIMLib.MESSAGE_TYPE.IMAGE) {
                    newInfo.push({
                        avatar: talk.avatar,
                        cUserId: talk.userId,
                        content: { type: 'image', content: message.content.imageUri, scale: message.content.content },
                        createTime: moment(message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                        createUserId: talk.userId,
                        id: message.receivedTime,
                        rUserId: rootState.user.user.id,
                        userName: talk.userName
                    })
                }
                commit('setCurrentTalkInfo', newInfo)
                commit('setCurrentTalkInfoMap', { userId: talk.userId, info: newInfo })
                // post(API.TALK.HISTORY_LIST, {
                //     cUserId: talk.userId,
                //     pageNo: 1,
                //     pageSize: 30
                // }).then(res => {
                //     let info = res.list.reverse().map(item => {
                //         let result = {
                //             ...item
                //         }
                //         try {
                //             result.content = JSON.parse(item.content)
                //         } catch {
                //             result.content = item.content
                //         }
                //         return result
                //     })
                //     commit('setCheckNum', { talk, total: info.length })
                //     commit('setTalktotal', { talk, total: +res.total + 1 })
                //     commit('setCurrentTalkInfoMap', { userId: talk.userId, info })
                //     commit('setCurrentTalkInfo', info)
                // })
            }

        },
        // 重置融云
        resetRongIMLib({ state, commit }) {
            if (state.im && state.imStatus) {
                state.im.disconnect().then(() => {
                    commit('setImStatus', false)
                })
            }
            commit('setTalkList', [])
        },
        // 初始化融云
        initRongIMLib({ commit, dispatch, state }, payload) {
            dispatch('getTalkList')
            let im
            if (!state.im) {
                im = RongIMLib.init({
                    appkey: RYAPPKEY
                })
                commit('setIm', im)
                dispatch('setRongListener', im)
            } else {
                im = state.im
            }
            if (!state.imStatus)
                dispatch('connectRong', im)
        },
        // 设置融云监听
        setRongListener({ state, rootState, commit }, im) {
            im.watch({
                // 监听消息通知
                message: (event) => {
                    let sendUserId = event.message.senderUserId
                    // 当前会话中
                    if (state.currentTalk && state.currentTalk.userId == sendUserId) {
                        // 接受文本消息
                        if (event.message.messageType === RongIMLib.MESSAGE_TYPE.TEXT) {
                            state.currentTalkInfo.push({
                                avatar: state.currentTalk.avatar,
                                cUserId: state.currentTalk.userId,
                                content: { content: event.message.content.content, type: 'message' },
                                createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                createUserId: state.currentTalk.userId,
                                id: event.message.receivedTime,
                                rUserId: rootState.user.user.id,
                                userName: state.currentTalk.userName
                            })
                        }
                        // 接受图片消息
                        if (event.message.messageType === RongIMLib.MESSAGE_TYPE.IMAGE) {
                            state.currentTalkInfo.push({
                                avatar: state.currentTalk.avatar,
                                cUserId: state.currentTalk.userId,
                                content: { type: 'image', content: event.message.content.imageUri, scale: event.message.content.content },
                                createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                createUserId: state.currentTalk.userId,
                                id: event.message.receivedTime,
                                rUserId: rootState.user.user.id,
                                userName: state.currentTalk.userName
                            })
                        }
                    }
                    // 没有再当前会话中
                    else {
                        // 接受文本消息
                        if (event.message.messageType === RongIMLib.MESSAGE_TYPE.TEXT) {
                            let talk = state.talkList.find(item => item.userId == sendUserId)
                            if (!talk) {
                                post(API.USER.PUBLIC_INFO, {
                                    rUserId: sendUserId
                                }).then(item => {
                                    let newTalk = {
                                        //头像
                                        avatar: item.avatar ?
                                            item.severPath + item.avatar :
                                            'http://img.xd.sidwit.com:80/image/fwpt/4c09700551ac452b975d65bfd318c4d1.jpg',
                                        //已加载信息数量
                                        checkNum: 0,
                                        // 总计信息数量
                                        totalNum: 0,
                                        //未读信息数量
                                        unreadNum: 1,
                                        // 对方用户id
                                        userId: item.userId,
                                        // 对方用户名称
                                        userName: item.userName,
                                        // 对方用户角色
                                        roleId: item.roleId,
                                        // 对方角色名
                                        roleName: item.roleIdName
                                    }
                                    state.talkList.unshift(newTalk)
                                    state.newMessageNumber += 1
                                    newTalk.checkNum += 1
                                    newTalk.unreadNum += 1
                                    let info = state.currentTalkInfoMap.get(+sendUserId)
                                    if (info) {
                                        info.push({
                                            avatar: newTalk.avatar,
                                            cUserId: newTalk.userId,
                                            content: { content: event.message.content.content, type: 'message' },
                                            createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                            createUserId: newTalk.userId,
                                            id: event.message.receivedTime,
                                            rUserId: rootState.user.user.id,
                                            userName: newTalk.userName
                                        })
                                    } else {
                                        state.currentTalkInfoMap.set(+sendUserId, [{
                                            avatar: newTalk.avatar,
                                            cUserId: newTalk.userId,
                                            content: { content: event.message.content.content, type: 'message' },
                                            createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                            createUserId: newTalk.userId,
                                            id: event.message.receivedTime,
                                            rUserId: rootState.user.user.id,
                                            userName: newTalk.userName
                                        }])
                                    }
                                })
                            } else {
                                talk.checkNum += 1
                                talk.unreadNum += 1
                                state.newMessageNumber += 1
                                let info = state.currentTalkInfoMap.get(+sendUserId)
                                if (info) {
                                    info.push({
                                        avatar: talk.avatar,
                                        cUserId: talk.userId,
                                        content: { content: event.message.content.content, type: 'message' },
                                        createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                        createUserId: talk.userId,
                                        id: event.message.receivedTime,
                                        rUserId: rootState.user.user.id,
                                        userName: talk.userName
                                    })
                                } else {
                                    state.currentTalkInfoMap.set(+sendUserId, [{
                                        avatar: talk.avatar,
                                        cUserId: talk.userId,
                                        content: { content: event.message.content.content, type: 'message' },
                                        createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                        createUserId: talk.userId,
                                        id: event.message.receivedTime,
                                        rUserId: rootState.user.user.id,
                                        userName: talk.userName
                                    }])
                                }
                            }
                        }
                        // 接受图片消息
                        if (event.message.messageType === RongIMLib.MESSAGE_TYPE.IMAGE) {
                            let talk = state.talkList.find(item => item.userId == sendUserId)
                            if (!talk) {
                                post(API.USER.PUBLIC_INFO, {
                                    rUserId: sendUserId
                                }).then(item => {
                                    let newTalk = {
                                        //头像
                                        avatar: item.avatar ?
                                            item.severPath + item.avatar :
                                            'http://img.xd.sidwit.com:80/image/fwpt/4c09700551ac452b975d65bfd318c4d1.jpg',
                                        //已加载信息数量
                                        checkNum: 0,
                                        // 总计信息数量
                                        totalNum: 0,
                                        //未读信息数量
                                        unreadNum: 1,
                                        // 对方用户id
                                        userId: item.userId,
                                        // 对方用户名称
                                        userName: item.userName,
                                        // 对方用户角色
                                        roleId: item.roleId,
                                        // 对方角色名
                                        roleName: item.roleIdName
                                    }
                                    state.talkList.unshift(newTalk)
                                    state.newMessageNumber += 1
                                    newTalk.checkNum += 1
                                    newTalk.unreadNum += 1
                                    let info = state.currentTalkInfoMap.get(+sendUserId)
                                    if (info) {
                                        info.push({
                                            avatar: newTalk.avatar,
                                            cUserId: newTalk.userId,
                                            content: { type: 'image', content: event.message.content.imageUri, scale: event.message.content.content },
                                            createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                            createUserId: newTalk.userId,
                                            id: event.message.receivedTime,
                                            rUserId: rootState.user.user.id,
                                            userName: newTalk.userName
                                        })
                                    } else {
                                        state.currentTalkInfoMap.set(+sendUserId, [{
                                            avatar: newTalk.avatar,
                                            cUserId: newTalk.userId,
                                            content: { type: 'image', content: event.message.content.imageUri, scale: event.message.content.content },
                                            createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                            createUserId: newTalk.userId,
                                            id: event.message.receivedTime,
                                            rUserId: rootState.user.user.id,
                                            userName: newTalk.userName
                                        }])
                                    }
                                })
                            } else {
                                talk.checkNum += 1
                                talk.unreadNum += 1
                                state.newMessageNumber += 1
                                let info = state.currentTalkInfoMap.get(+sendUserId)
                                if (info) {
                                    info.push({
                                        avatar: talk.avatar,
                                        cUserId: talk.userId,
                                        content: { type: 'image', content: event.message.content.imageUri, scale: event.message.content.content },
                                        createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                        createUserId: talk.userId,
                                        id: event.message.receivedTime,
                                        rUserId: rootState.user.user.id,
                                        userName: talk.userName
                                    })
                                } else {
                                    state.currentTalkInfoMap.set(+sendUserId, [{
                                        avatar: talk.avatar,
                                        cUserId: talk.userId,
                                        content: { type: 'image', content: event.message.content.imageUri, scale: event.message.content.content },
                                        createTime: moment(event.message.receivedTime).format('YYYY-MM-DD HH:mm:ss'),
                                        createUserId: talk.userId,
                                        id: event.message.receivedTime,
                                        rUserId: rootState.user.user.id,
                                        userName: talk.userName
                                    }])
                                }
                            }
                        }
                    }
                },
            })
        },
        // 连接融云账户
        connectRong({ rootState, commit, dispatch }, im) {
            let token = rootState.user.user.rytoken
            if (!token) return;
            im.connect({ token }).then(user => {
                commit('setImStatus', true)
            }).catch(e => {
                console.log('融云登陆失败', e.code, e.msg)
                setTimeout(() => {
                    dispatch('connectRong', im)
                }, 10 * 1000)
            })
        },
    }
}