import Vue from 'vue'
import Vuex from 'vuex'
import baseInfo from './modules/baseInfo'
import user from "./modules/user"
import permission from "./modules/permission"
import map from "./modules/map"
import rongIMLib from './modules/rongIMLib'
import msg from "./modules/msg"
import { Message } from 'view-design';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    initApp({ dispatch }) {
      Message.loading({
        content: '用户数据加载中...',
        duration: 0
      });
      return new Promise((resolve, reject) => {
        dispatch('getUserInfo').then(() => {
          dispatch('initRongIMLib')
          return dispatch('getPermission')
        }).then(() => {
          Message.destroy();
          resolve();
        }).catch(e => {
          Message.destroy();
          reject(e);
        });
      })
    },
    clearApp({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('clearUserInfo').then(() => {
          dispatch('clearMap')
          dispatch('resetRongIMLib')
          return dispatch('clearPermission')
        }).then(() => {
          localStorage.clear()
          resolve();
        }).catch(e => {
          reject(e);
        });
      })
    }
  },
  modules: {
    baseInfo,
    user,
    permission,
    map,
    rongIMLib,
    msg
  }
})
