import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./router"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name == 'login' && (!localStorage.token || !localStorage.userId || !localStorage.companyNo)) {
    return next()
  }
  if (to.name == 'login' && localStorage.token && localStorage.userId && localStorage.companyNo) {
    router.app.$options.store.dispatch('clearApp').then(
      () => {
        next({ name: 'login' })
        location.reload()
      }
    )
  }
  let userReady = router.app.$options.store.state.user.userReady;
  let permissionReady = router.app.$options.store.state.permission.permissionReady;
  if (!userReady || (!permissionReady && to.name != 'informationPerfection')) {
    router.app.$options.store.dispatch('initApp').then(() => {
      next({ ...to, replace: true })
    }).catch(e => {
      if (e) {
        next()
      } else {
        console.log(e)
        router.app.$options.store.dispatch('clearApp').then(
          () => next({ name: 'login' })
        )
      }
    })
  } else {
    next()
  }
})

export default router
