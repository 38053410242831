<template>
  <h-modal
    :loading="options.loading"
    :width="options && options.width ? options.width : 520"
    :value="value"
    @input="(value) => $emit('input', value)"
    :title="title"
    @on-ok="submit"
  >
    <template v-if="options && options.rules && options.model">
      <Form
        class="h-table-form-modal"
        ref="form"
        :model="options.model"
        :label-width="100"
      >
        <Row type="flex">
          <template v-for="(rule, name) in options.rules">
            <Col
              :key="name"
              :span="rule.col ? rule.col : options.col ? options.col : 24"
            >
              <FormItem v-bind="calcBind(name, rule)">
                <Input
                  :disabled="type == 'check' || rule.disabled ? true : false"
                  @on-change="
                    (val) => {
                      rule.onChange && rule.onChange(val);
                    }
                  "
                  :readonly="rule.readonly ? true : false"
                  :rows="4"
                  v-model="options.model[name]"
                  :type="rule.type == 't' ? 'textarea' : 'text'"
                  v-if="rule.type == 'i' || rule.type == 't'"
                >
                  <template v-if="rule.append">
                    <span slot="append">{{ rule.append }}</span>
                  </template>
                </Input>
                <Select
                  transfer
                  :disabled="type == 'check' || rule.disabled ? true : false"
                  v-if="rule.type == 's'"
                  v-model="options.model[name]"
                  @on-change="
                    (val) => {
                      rule.onChange && rule.onChange(val);
                    }
                  "
                >
                  <template v-if="selectDatas[name]">
                    <Option
                      :key="option.value"
                      :value="option.value"
                      v-for="option in selectDatas[name]"
                    >
                      {{ option.label }}
                    </Option>
                  </template>
                </Select>
                <Cascader
                  transfer
                  :disabled="type == 'check' || rule.disabled ? true : false"
                  @on-change="
                    (val) => {
                      rule.onChange && rule.onChange(val);
                    }
                  "
                  v-if="rule.type == 'c'"
                  v-model="options.model[name]"
                  :data="selectDatas[name]"
                >
                </Cascader>
                <DatePicker
                  transfer
                  v-if="rule.type == 'd' || rule.type == 'date'"
                  @on-change="
                    (val) => {
                      rule.onChange && rule.onChange(val);
                    }
                  "
                  v-model="options.model[name]"
                >
                </DatePicker>
                <upload-image
                  :clearFlag="!value"
                  :readonly="type == 'check'"
                  v-if="rule.type == 'image'"
                  v-model="options.model[name]"
                >
                </upload-image>
              </FormItem>
            </Col>
          </template>
        </Row>
      </Form>
    </template>
    <slot name="footer" slot="footer">
      <div style="text-align: center">
        <Button
          v-if="type == 'add' || type == 'edit'"
          :loading="options.loading"
          @click="submit"
          type="primary"
          >提交</Button
        >
        <Button @click="$emit('input', false)">{{
          type == "check" ? "确定" : "取消"
        }}</Button>
      </div>
    </slot>
  </h-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: String,
    title: String,
    options: Object,
  },
  data() {
    return {
      selectDatas: {},
    };
  },
  computed: {
    // 计算绑定
    calcBind() {
      return (attrName, rule) => {
        let result = {
          // key: attrName,
          prop: attrName,
          label: rule.name,
        };
        let rules = [];
        if (rule.required) {
          rules.push({
            required: true,
            message: rule.name + "不能为空",
          });
        }
        if (rule.rules) {
          if (Array.isArray(rule.rules)) {
            rules.push(...rule.rules);
          } else rules.push(rule.rules);
        }
        rules.length > 0 && (result.rules = rules);
        return result;
      };
    },
  },
  watch: {
    value(visible) {
      if (visible) return;
      this.$refs.form.resetFields();
    },
  },
  methods: {
    // 处理需要加载的数据
    dealwidthRules() {
      if (!this.options?.rules) return;
      let result = {};
      for (let i in this.options.rules) {
        let item = this.options.rules[i];
        if (item.hasOwnProperty("getControl")) {
          item.getControl(this, "selectDatas");
        }
        if (item.data && typeof item.data == "function") {
          let temp = item.data();
          if (temp.then) {
            result[i] = [];
            temp.then((list) => {
              let selectDatas = { ...this.selectDatas, [i]: list };
              this.selectDatas = selectDatas;
            });
          } else {
            result[i] = temp;
          }
        } else if (item.data) result[i] = item.data;
      }
      this.selectDatas = result;
    },
    // 点击提交
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.$emit("on-ok");
      });
    },
  },
  mounted() {
    this.dealwidthRules();
  },
};
</script>
<style lang='less' scoped>
@import url("./model.less");
</style>
<style>
.green-theme .h-table-form-modal .ivu-input[disabled],
.blue-theme .h-table-form-modal .ivu-input[disabled],
.purple-theme .h-table-form-modal .ivu-input[disabled] {
  background-color: #fff;
  color: #17233d;
}
.green-theme .h-table-form-modal .ivu-select-disabled .ivu-select-selection,
.blue-theme .h-table-form-modal .ivu-select-disabled .ivu-select-selection,
.purple-theme .h-table-form-modal .ivu-select-disabled .ivu-select-selection {
  background-color: #fff;
  color: #17233d;
}
</style>