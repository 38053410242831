const host = process.env.NODE_ENV == 'development' ?
  // "https://castoff.hogdata.cn" :
  'http://192.168.1.148:8005' : //本地
  // 'http://192.168.1.72:10083' : //本地
  "https://castoff.hogdata.cn";

const wsHost = process.env.NODE_ENV == 'development' ?
  "wss://castoff.hogdata.cn/websocket" :
  "wss://castoff.hogdata.cn/websocket"


const yingyanHost = process.env.NODE_ENV == 'development' ?
  '/api/v3' :
  // 'https://yingyan.baidu.com/api/v3' :
  'https://yingyan.baidu.com/api/v3'

module.exports = { host, wsHost, yingyanHost }
