import XLSX from 'xlsx';
import { post } from "@/common/http"
const CU = {
    // 查找树形结构获得指定子内容
    searchInTreeData(list = [], options) {
        let key = options?.key ?? 'id';
        let value = options?.value ?? '';
        let children = options?.children ?? 'childList';
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if ((item[key] + '') === (value + '')) {
                return item;
            }
            if (item[children] && item[children].length > 0) {
                let subresult = CU.searchInTreeData(item[children], options)
                if (subresult) return subresult;
            }
        }
        return null;
    },
    //验证数字
    validateNumber(value, min = 0, max, fixed = 2) {
        if (!value && value !== 0) return false;
        let patternString = fixed ? `[-|+]?\\d+(\\.\\d{0,${fixed}})?` : `[-|+]?\\d+`;
        let reg = new RegExp(patternString);
        let fitResult = (value + '').match(reg);
        if (!fitResult) return false;
        if (fitResult[0].length !== (value + '').length) return false;
        if ((min || min === 0) && (+value) < min) return false;
        if (max && (+value) > max) return false;
        return true;
    },
    //根据最终结果查找Cascader结构value数组
    findCascaderValue(data = [], value) {
        let result = [];
        data.forEach(item => {
            if (result.length !== 0) return;
            if (item.value + '' === value + '') {
                result.push(item.value);
            } else {
                if (item.children && item.children.length > 0) {
                    let subResult = CU.findCascaderValue(item.children, value);
                    if (subResult.length > 0) {
                        result = [item.value, ...subResult];
                    }
                }
            }
        });
        return result;
    },
    //格式化数据为cascader组件树形结构
    formatTreeData(data = [], valueKey = 'id', labelKey = 'name', childrenKey = 'childList', retainKeys = []) {
        let result = data.map(item => {
            let itemResult = {
                value: item[valueKey],
                label: item[labelKey]
            };
            retainKeys.forEach(key => {
                itemResult[key] = item[key]
            })
            if (item[childrenKey] && Array.isArray(item[childrenKey]) && item[childrenKey].length > 0) {
                itemResult.children = CU.formatTreeData(item[childrenKey], valueKey, labelKey, childrenKey, retainKeys);
            }
            return itemResult;
        });
        return result
    },
    // 格式化数据为树形组件的格式
    formatTreeComponentData(data = [], title_key = "name", children_key = 'childList', delete_origin = true, default_expand = () => true, default_checked = () => false) {
        data.forEach((item, key) => {
            item.title = item[title_key];
            item[children_key] && (item.children = CU.formatTreeComponentData(item[children_key], title_key, children_key, delete_origin, default_expand, default_checked));
            default_expand(item) && (key === 0 ? item.expand = true : item.expand = false);
            default_checked(item) && (item.checked = true);
            if (item.children && item.children.length > 0) {
                delete item.checked
            }
            if (delete_origin) {
                delete item[title_key];
                if (children_key !== 'children') {
                    delete item[children_key];
                }
            }
        })
        return data;
    },
    formatTreeTable(data = [], children_key = 'childList') {
        data.forEach(item => {
            item.children = item[children_key]
            if (item[children_key] && item[children_key].length > 0) {
                CU.formatTreeTable(item[children_key], children_key)
            }
            if (children_key !== 'children') {
                delete item[children_key]
            }
        })
        return data
    },
    // 遍历树形接口,执行指定操作
    forEachTree(data, func, child_string = 'childList') {
        if (!data) return;
        data.forEach((item) => {
            func(item);
            if (item[child_string]) {
                CU.forEachTree(item[child_string], func, child_string);
            }
        })
    },
    /**
   * 导出Excel
   * columns 表格列名
   * data 表格数据 类型为二维数组
   * name 表格名称
   * isMerge 是否合并
   * merge 需要合并的单元格
   */
    exportExcel(columns, data, name, isMerge = false, merge = []) {
        let ws_data = [columns, ...data];
        let ws = XLSX.utils.aoa_to_sheet(ws_data);
        let wb = XLSX.utils.book_new();
        if (isMerge) {
            ws['!ref'] = `A1:ZZ${ws_data.length}`
            ws['!merges'] = merge
        }
        XLSX.utils.book_append_sheet(wb, ws, name);
        XLSX.writeFile(wb, `${name}.xlsx`);
    },


    //根据id查找分类名称
    findClassName(data, id, value_key = "id", label_key = "name", children_key = 'childList') {
        // debugger;
        let result = ''
        data.forEach(item => {
            if (item[value_key] === id) {
                result = item[label_key]
            } else if (item[children_key] && item[children_key].length > 0) {
                let subResult = CU.findClassName(item[children_key], id, value_key, label_key, children_key)
                if (subResult.length > 0) {
                    result = subResult
                }
            }
        })
        return result
    },

    //树形结构只展开一个节点
    expandOne(treeData, pid, id, pid_key = "pid", id_key = "id") {
        treeData.forEach((item) => {
            if (String(pid) === String(item[pid_key]) && String(item[id_key]) !== String(id)) {
                item.expand = false;
            } else if (item.children && item.children.length > 0) {
                CU.expandOne(item.children, pid, id, pid_key, id_key);
            }
        });
        return treeData;
    },

    // 数组去重
    distinct(arr) {
        return [...new Set(arr)];
    },
    //数组内对象去重
    distinct2(arr, field) {
        let obj = {}
        let result = arr.reduce((item, next) => {
            obj[next[field]] ? "" : obj[next[field]] = true && item.push(next)
            return item
        }, [])
        return result
    },

    //验证手机号码
    validatePhone(value) {
        if (!value) return 0;
        let rule = /^1[3|4|5|7|8|9][0-9]{9}$/;
        let result = (value + '').match(rule);
        if (!result) return false;
        if (result[0].length !== (value + '').length) return false;
        return true;
    },

    //验证座机号码
    validateLandline(value) {
        if (!value) return 0;
        let rule = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
        let result = (value + '').match(rule);
        if (!result) return 0;
        if (result[0].length !== (value + '').length) return 0;
        return 1;
    },

    //计算时间
    calculatingTime(value) {
        if (value < 60 && value >= 0) {
            return value ? value + "秒" : "";
        } else if (value >= 60 && value < 60 * 60) {
            let second = value % 60;
            let minute = (value - second) / 60;
            return minute + "分" + (second ? second + "秒" : "");
        } else {
            let second = (value % 60) % 60;
            let minute = ((value - second) / 60) % 60;
            let hour = (value - minute * 60 - second) / 60 / 60;
            return hour + "小时" + minute + "分" + (second ? second + "秒" : "");
        }
    },

    //验证邮箱
    validateEmail(rule, value, callback) {
        if (value === '') {
            callback(new Error('请正确填写邮箱'));
        } else {
            if (value !== '') {
                var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (!reg.test(value)) {
                    callback(new Error('请输入有效的邮箱'));
                }
            }
            callback();
        }
    },
    // 将rgba颜色转成hex
    colorRGB2Hex(color) {
        let rgba = color.split('(')[1].split(')')[0].split(',')
        var r = parseInt(rgba[0]);
        var g = parseInt(rgba[1]);
        var b = parseInt(rgba[2]);
        let opacity = rgba.length > 3 ? rgba[3] : 1

        var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
        return { hex, opacity };
    },
    // 将hex颜色转成rgb
    hexToRgba(hex, opacity) {
        let RGBA = "rgba(" + parseInt("0x" + hex.slice(1, 3)) + "," + parseInt("0x" + hex.slice(3, 5)) + "," + parseInt("0x" + hex.slice(5, 7)) + "," + opacity + ")";
        return {
            red: parseInt("0x" + hex.slice(1, 3)),
            green: parseInt("0x" + hex.slice(3, 5)),
            blue: parseInt("0x" + hex.slice(5, 7)),
            rgba: RGBA
        }
    },
    filterAsyncRouter(data) {
        let permission = []
        let routers = {
            path: '/main',
            name: 'main',
            component: () => import('@/views/main'),
            children: []
        }
        let routers2 = {
            path: '/noLeft',
            name: 'noLeft',
            component: () => import('@/views/main/noLeft'),
            children: []
        }
        data.forEach(item => {
            if (item.levels == 2) {
                let obj = {
                    path: `/${item.url}`,
                    name: item.url,
                    meta: {
                        title: item.name,
                        hidden: item.isOpen == 1 ? false : true
                    },
                    component: () => import(`@/views/${item.tips}`)
                }
                if (item.template == 'noLeft') {
                    routers2.children.push(obj)
                } else {
                    routers.children.push(obj)
                }
            } else if (item.levels == 3) {
                permission.push(item.url)
            }
            if (item.children && item.children.length > 0) {
                let result = CU.filterAsyncRouter(item.children)
                permission = permission.concat(result.permission)
                routers.children = routers.children.concat(result.routers.children)
                routers2.children = routers2.children.concat(result.routers2.children)
            }
        })
        return { permission, routers, routers2 }
    },
    //导入
    loadEnter(url, user) {
        return new Promise(resolve => {
            let input = document.createElement("input");
            input.type = "file";
            input.onchange = (e) => {
                if (!input.files || input.files.length === 0) return;
                //通过FormData构造函数创建一个空对象
                let formdata = new FormData();
                //通过append()方法来追加数据
                formdata.append("file", input.files[0]);
                formdata.append("userId", localStorage.getItem("userId"));
                formdata.append("companyNo", localStorage.getItem("companyNo"));
                formdata.append("category", user.category + 1);
                post(url, formdata, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => {
                    resolve(res)
                });
            };
            input.click();
        })
    },

    removePath(url, count = 3) {
        let n = url.indexOf('/')
        for (let i = 0; i < count - 1; i++) {
            n = url.indexOf('/', n + 1)
        }
        return url.substring(n + 1)
    }
}

export function getRandomId(count = 6) {
    let str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_';
    let rstr = ''
    for (let i = 0; i < count; i++) {
        let pos = Math.round(Math.random() * str.length - 1);
        rstr += str[pos]
    }
    return rstr;
}

export function jsonp(url, callback) {
    let name = 'callback' + new Date().getTime().toString();
    if (callback) {
        window[name] = callback
        let index = url.indexOf('?');
        if (index === -1) url += `?callback=${name}`
        else url += `&callback=${name}`
    }
    var script = document.createElement('script');
    script.src = url;
    script.id = 'jsonp';
    document.getElementsByTagName('head')[0].appendChild(script);
    script.onload = function (e) {
        document.getElementById('jsonp').remove();
        delete window[name]
    };
}

export default CU;