<template>
  <div class="table-page">
    <filter-pane
      v-if="config.filter"
      :condition="config.filter"
      @search="search"
      @add="add"
      @loadEnter="loadEnter"
      @loadExpend="loadExpend"
      @custom="custom"
      @sel-cb="selCb"
      @foucesed="foucesed"
      ref="filter"
    >
      <template #other>
        <slot name="otherFilter"></slot>
      </template>
    </filter-pane>
    <div style="flex-shirk: 0; flex-grow: 0">
      <slot name="other"></slot>
    </div>
    <div
      ref="tableBox"
      :style="{ paddingBottom: this.showSummary ? '50px' : '' }"
      class="table-box"
    >
      <Table ref="table" v-on="eventsProps" v-bind="nextProps"> </Table>
    </div>
    <!-- <div v-if="this.showSummary" class="table-summary-position"></div> -->
    <div class="table-footer" v-if="new Set(Object.keys(config)).has('page')">
      <div style="height: 50px; text-align: left" v-if="config.summary">
        <span>统计：</span>
        <span
          style="display: inline-block; margin-right: 10px"
          v-for="(item, index) in config.summary"
          :key="index"
        >
          <span>{{ item.sumName ? item.sumName : "" }}：</span>
          <span>{{ item.sumVal ? item.sumVal : 0 }}</span>
          <span style="margin-left: 2px">{{ item.unit ? item.unit : "" }}</span>
        </span>
      </div>
      <Page
        :current="config.page.pageNo"
        :page-size="config.page.pageSize"
        :total="config.page.total"
        :show-elevator="config.page.page_size ? false : true"
        :show-total="config.page.page_size ? false : true"
        :size="config.page.page_size"
        show-sizer
        :page-size-opts="[10, 20, 30, 100]"
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      ></Page>
    </div>
  </div>
</template>
<script>
import filterPane from "./filter";
export default {
  components: {
    filterPane,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      default() {
        return !this.$IVIEW || this.$IVIEW.size === ""
          ? "default"
          : this.$IVIEW.size;
      },
    },
    width: {
      type: [Number, String],
    },
    height: {
      type: [Number, String],
    },
    // 3.4.0
    maxHeight: {
      type: [Number, String],
    },
    stripe: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    highlightRow: {
      type: Boolean,
      default: false,
    },
    rowClassName: {
      type: Function,
      default() {
        return "";
      },
    },
    context: {
      type: Object,
    },
    noDataText: {
      type: String,
    },
    noFilteredDataText: {
      type: String,
    },
    disabledHover: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    tooltipTheme: {
      default: "dark",
    },
    // 4.5.0
    tooltipMaxWidth: {
      type: Number,
      default: 300,
    },
    // #5380 开启后，:key 强制更新，否则使用 index
    // 4.1 开始支持 String，指定具体字段
    rowKey: {
      type: [Boolean, String],
      default: false,
    },
    // 4.0.0
    spanMethod: {
      type: Function,
    },
    // 4.0.0
    showSummary: {
      type: Boolean,
      default: false,
    },
    // 4.0.0
    summaryMethod: {
      type: Function,
    },
    // 4.0.0
    sumText: {
      type: String,
    },
    // 4.1.0
    indentSize: {
      type: Number,
      default: 16,
    },
    // 4.1.0
    loadData: {
      type: Function,
    },
    // 4.4.0
    updateShowChildren: {
      type: Boolean,
      default: false,
    },
    // 4.1.0
    contextMenu: {
      type: Boolean,
      default: false,
    },
    // 4.2.0
    showContextMenu: {
      type: Boolean,
      default: false,
    },

    notHeight: {
      type: Boolean,
      default: false,
    },

    /**
       * 示例：
       * @param filter Object 对象
       * @param filterBox Array 筛选条件数组数组
       * @param addBtnName String 添加按钮名称
       * @param conditionName String 筛选名称
       * @param component String 要使用的组件
       * @param field String 要筛选的字段
       * @param defaultValue any 筛选字段默认值
       * @param data Array 组件为select和cascader时必传
       * @param parameterField String 组件为select时必传，要获取的数据字段
       * @param showField String 组件为select时必传，要显示的数据字段
       * @param isCallback Boolean 是否有选择回调
       * @param mark String or Number 标识
       * @param isLoadData Boolean 是否异步加载,cascader有效
       * @param loadData Function 方法，配合isLoadData使用,cascader有效
       * @param isChangeOnSelect Boolean 是否点选每级菜单选项值都会发生变化
       * @param isBack Boolean 是否需要返回按钮
       * @param filterable Boolean 组件为select时可选用，是否支持搜索
       * config: {
       *  filter: {
       *    add: {
       *      addBtnName: "新增示例",
       *      ca: ''
       *    }
            width: 200,
            isBack: false,
            filterBox: [
              {
                conditionName: "示例",
                component: "input",
                field: "productName",
                defaultValue: "",
              },
              {
                conditionName: "示例",
                component: "select",
                field: "productName",
                defaultValue: "",
                data: [],
                parameterField: "id",
                showField: "name",
                isCallback: true,
                mark: '1',
                filterable: true
              }
              {
                conditionName: "示例",
                component: "cascader",
                field: "productName",
                defaultValue: [],
                data: [],
              }
            ],
          },
          page: {
            pageNo: 1,
            pageSize: 10,
            total: 0,
            page_size: "small"
          }
      * }
    */
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    // child: {
    //   type: String,
    //   default: "childList",
    // },
  },
  data() {
    return {
      // 传递给table的props
      nextProps: {},
      // 传递给Table的事件处理
      eventList: [
        "on-current-change",
        "on-select",
        "on-select-cancel",
        "on-select-all",
        "on-select-all-cancel",
        "on-select-change",
        "on-sort-change",
        "on-filter-change",
        "on-row-click",
        "on-row-dblclick",
        "on-cell-click",
        "on-expand",
        "on-drag-drop",
        "on-column-width-resize",
        "on-contextmenu",
        "on-expand-tree",
      ],
      // open_children: new Map(),
    };
  },
  computed: {
    eventsProps() {
      let result = {};
      for (let i = 0; i < this.eventList.length; i++) {
        let name = this.eventList[i];
        result[name] = (a, b, c, d) => {
          this.doEvent(name, a, b, c, d);
        };
      }
      return result;
    },
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.setNextProps();
      },
    },
  },
  methods: {
    exportCsv(options) {
      this.$refs.table.exportCsv(options);
    },
    clearCurrentRow() {
      this.$refs.table.clearCurrentRow();
    },
    selectAll(options) {
      this.$refs.table.selectAll(options);
    },
    doEvent(name, a, b, c, d) {
      this.$emit(name, a, b, c, d);
    },
    // 组合传给子组件的props
    setNextProps() {
      let nextProps = {};
      for (let key in this.$props) {
        if (this.$props[key] === undefined) continue;
        nextProps[key] = this.$props[key];
      }
      if (!nextProps.notHeight) {
        let height = Math.floor(this.$refs.tableBox.clientHeight);
        if (nextProps.showSummary) {
          let summayHeight =
            nextProps.size == "small"
              ? 40
              : nextProps.size == "large"
              ? 60
              : 48;
          height -= summayHeight;
        }
        nextProps.height = height;
      }
      // if (nextProps.rowKey) {
      //   this.open_children = new Map() ;
      //   this.setOpenChildren(this.data);
      //   nextProps.data = this.data.map((item) => {
      //     let obj = {
      //       ...item,
      //       children: [],
      //       _loading: false,
      //       _showChildren: false,
      //     };
      //     return obj
      //   });
      // }
      this.nextProps = nextProps;
    },
    // setOpenChildren(data) {
    //   data.forEach((item) => {
    //     if (item.pid != "0") {
    //       if (item[this.child] && item[this.child].length > 0) {
    //         item._loading = false;
    //         item._showChildren = false;
    //       }
    //       item.children = [];
    //       if (!this.open_children.has(item.pid)) {
    //         this.open_children.set(item.pid, [item]);
    //       } else {
    //         let n = this.open_children.get(item.pid);
    //         n.push(item);
    //         this.open_children.set(item.pid, n);
    //       }
    //     }
    //     if (item[this.child] && item[this.child].length > 0) {
    //       this.setOpenChildren(item[this.child]);
    //     }
    //   });
    // },
    // handleLoadData(item, callback) {
    //   let data;
    //   if (this.open_children.has(item.id)) {
    //     data = this.open_children.get(item.id);
    //   }
    //   callback(data);
    // },

    search(data) {
      this.$emit("search", data);
    },

    add() {
      this.$emit("add");
    },
    pageChange(pageNo) {
      this.$emit("page-change", pageNo);
    },
    pageSizeChange(pageSize) {
      this.$emit("page-size-change", pageSize);
    },
    loadEnter() {
      this.$emit("loadEnter");
    },
    loadExpend() {
      this.$emit("loadExpend");
    },
    custom(data) {
      this.$emit("custom",data);
    },
    selCb(val, mark) {
      this.$emit("selCb", val, mark);
    },
    setDefaults() {
      this.$refs.filter.setDefaults();
    },
    foucesed() {
      this.$emit("foucesed");
    },
  },
  mounted() {
    this.setNextProps();
    window.addEventListener("resize", this.setNextProps);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setNextProps);
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
// /deep/ .ivu-table-wrapper {
//   overflow: scroll;
// }
</style>