<template>
  <div ref="parent" class="scroll-box">
    <div
      :style="{
        [alignDirection]: `${alignDirection == 'top' ? '-' : ''}${y}px`,
        left: `-${x}px`,
        ...innerStyle,
      }"
      ref="child"
      class="scroll-container"
    >
      <slot></slot>
    </div>
    <div @click="scrollDown" class="up-button" v-show="showButton && y > 0">
      <slot name="up-bottun">
        <div class="default-button">
          <span class="iconfont">&#xe6c6;</span>
        </div>
      </slot>
    </div>
    <div @click="scrollUp" class="down-button" v-show="showButton && y < maxY">
      <slot name="down-bottun">
        <div class="default-button">
          <span class="iconfont">&#xe6c7;</span>
        </div>
      </slot>
    </div>
    <div @click="scrollRight" class="left-button" v-show="showButton && x > 0">
      <slot name="left-button">
        <div class="default-button">
          <span class="iconfont">&#xe607;</span>
        </div>
      </slot>
    </div>
    <div
      @click="scrollLeft"
      class="right-button"
      v-show="showButton && x < maxX"
    >
      <slot name="right-button">
        <div class="default-button">
          <span class="iconfont">&#xe6c5;</span>
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    //   滚动方向(竖向:y/vetical  水平 x/horizontal)
    direction: {
      type: String,
      default: "y",
    },
    // 显示按钮
    showButton: {
      type: Boolean,
      default: true,
    },
    // 对齐底部还是头部
    alignDirection: {
      type: String,
      default: "top",
    },
    // 内部样式
    innerStyle: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      scrollable: false,
      x: 0,
      maxX: 0,
      y: 0,
      maxY: 0,
      ySpeed: 2000,
    };
  },
  watch: {
    scrollable(val) {
      if (val) {
        this.setMouseListener();
      } else {
        this.$refs.child.removeEventListener("wheel", this.mouseWheelDeal);
      }
    },
  },
  methods: {
    // 初始化
    init() {
      this.judgeScrollable();
      if (this.scrollable) {
        this.setMouseListener();
      }
    },
    // 判读是否可滚动
    judgeScrollable() {
      let veticalInterval = setInterval(() => {
        try {
          let parent = this.$refs.parent;
          let child = this.$refs.child;
          if (this.direction === "y" || this.direction === "vetical") {
            let parentHeight = parent.clientHeight;
            let childHeight = child.scrollHeight;
            if (this.alignDirection == "top") {
              this.maxY =
                childHeight - parentHeight < 0 ? 0 : childHeight - parentHeight;
              if (this.y > this.maxY) {
                this.y = this.maxY;
              }
            } else if (this.alignDirection == "bottom") {
              this.maxY =
                childHeight - parentHeight < 0 ? 0 : parentHeight - childHeight;
              if (this.y < this.maxY) {
                this.y = this.maxY;
              }
            }
            this.scrollable = childHeight > parentHeight;
          } else if (
            this.direction === "x" ||
            this.direction === "horizontal"
          ) {
            let parentWidth = parent.clientWidth;
            let childWidth = child.scrollWidth;
            this.maxX =
              childWidth - parentWidth < 0 ? 0 : childWidth - parentWidth;
            this.x > this.maxX && (this.x = this.maxX);
            this.scrollable = childWidth > parentWidth;
          }
        } catch {
          clearInterval(veticalInterval);
        }
      }, 0.5 * 1000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(veticalInterval);
      });
    },
    // 设置滚动滚轮监听
    setMouseListener() {
      this.$refs.child.addEventListener("wheel", this.mouseWheelDeal);
    },
    // 鼠标滚动事件
    mouseWheelDeal(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.direction === "y" || this.direction === "vetical") {
        let dy = e.deltaY;
        let nextY = dy + this.y;
        if (this.alignDirection == "top") {
          nextY < 0 && (nextY = 0);
          nextY > this.maxY && (nextY = this.maxY);
          this.y = nextY;
        } else if (this.alignDirection == "bottom") {
          nextY < this.maxY && (nextY = this.maxY);
          nextY > 0 && (nextY = 0);
          this.y = nextY;
        }
      } else if (this.direction === "x" || this.direction === "horizontal") {
        let dx = e.deltaY;
        let nextX = dx + this.x;
        nextX < 0 && (nextX = 0);
        nextX > this.maxX && (nextX = this.maxX);
        this.x = nextX;
      }
    },
    // 内容向下移动
    scrollDown() {
      // 获取面板高度
      let clientHeight;
      try {
        clientHeight = this.$refs.parent.clientHeight;
      } catch {
        clientHeight = 0;
      }
      if (!clientHeight) return;
      let nextY = this.y - clientHeight;
      if (nextY < 0) nextY = 0;
      let moveY = this.y - nextY;
      let time = Math.floor((moveY / this.ySpeed) * 1000);
      this.$refs.child.style.transition = `top linear ${time / 1000}s`;
      this.y = nextY;
      setTimeout(() => {
        this.$refs.child.style.transition = "none";
      }, time);
    },
    // 内容向上移动
    scrollUp() {
      // 获取面板高度
      let clientHeight;
      try {
        clientHeight = this.$refs.parent.clientHeight;
      } catch {
        clientHeight = 0;
      }
      if (!clientHeight) return;
      let nextY = clientHeight + this.y;
      if (nextY > this.maxY) nextY = this.maxY;
      let moveY = nextY - this.y;
      let time = Math.floor((moveY / this.ySpeed) * 1000);
      this.$refs.child.style.transition = `top linear ${time / 1000}s`;
      this.y = nextY;
      setTimeout(() => {
        this.$refs.child.style.transition = "none";
      }, time);
    },
    // 内容移动至至上方
    scrollUpBest() {
      this.y = this.maxY;
    },
    // 内容向右移动
    scrollRight() {
      // 获取面板宽度
      let clientWidth;
      try {
        clientWidth = this.$refs.parent.clientWidth;
      } catch {
        clientWidth = 0;
      }
      if (!clientWidth) return;
      let nextX = this.x - clientWidth;
      if (nextX < 0) nextX = 0;
      let moveX = this.x - nextX;
      let time = Math.floor((moveX / this.ySpeed) * 1000);
      this.$refs.child.style.transition = `left linear ${time / 1000}s`;
      this.x = nextX;
      setTimeout(() => {
        this.$refs.child.style.transition = "none";
      }, time);
    },
    // 内容向左移动
    scrollLeft() {
      // 获取面板宽度
      let clientWidth;
      try {
        clientWidth = this.$refs.parent.clientWidth;
      } catch {
        clientWidth = 0;
      }
      if (!clientWidth) return;
      let nextX = this.x + clientWidth;
      if (nextX > this.maxX) nextX = this.maxX;
      let moveX = nextX - this.x;
      let time = Math.floor((moveX / this.ySpeed) * 1000);
      this.$refs.child.style.transition = `left linear ${time / 1000}s`;
      this.x = nextX;
      setTimeout(() => {
        this.$refs.child.style.transition = "none";
      }, time);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
<style lang="less">
.blue-theme .default-button {
  color: #2d8cf0;
}
.green-theme .default-button {
  color: #06baa1;
}
.purple-theme .default-button {
  color: #8d0077;
}
</style>