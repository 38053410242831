export const MAP_PK = 'pk.eyJ1Ijoic3VwZXJoeGoiLCJhIjoiY2tpOGEwYmRtMDNyODJ3cWxiN2Fmc3FheCJ9.tIBVrv-o8fKWdVz92Q5jqw';
export const AMAP_KEY = '755b203e2c7eb074e10977e98cf07fad'
export const BMAP_AK = "0XhRU1xTqZipGGOKCkpdhaIeGHzbxNaY"
// 百度鹰眼服务ID Service ID
export const SERVICE_ID = '227564'
// 融云appkey
export const RYAPPKEY = '82hegw5u8ek4x'
// cesium地图token
export const cesiumToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYTUxOGQ0My1kZmYxLTQwMDktODdlYS1kZDM4ZjNhMGI5NzMiLCJpZCI6NTIyODcsImlhdCI6MTYxODUzODgxMX0.ToAGzDlsMu7-omyDs_bU98rYh1_kMRmX3NBFaKJwtOM'
// 天地图
export const tiandituToken = 'abddf460b4e65fbeda6a67398e272672'