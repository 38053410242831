<template>
  <Modal v-on="eventBind" v-bind="$props">
    <div
      :style="{
        maxHeight: maxHeight ? maxHeight : 'calc(100vh - 250px)',
        overflow: 'auto',
      }"
    >
      <slot> </slot>
    </div>
    <slot slot="footer" name="footer">
      <div style="text-align: center">
        <Button :loading="loading" @click="$emit('on-ok')" type="primary"
          >提交</Button
        >
        <Button @click="$emit('input', false)">取消</Button>
      </div>
    </slot>
  </Modal>
</template>
<script>
export default {
  props: {
    maxHeight: String,
    value: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    maskClosable: {
      type: Boolean,
      default() {
        return !this.$IVIEW || this.$IVIEW.modal.maskClosable === ""
          ? true
          : this.$IVIEW.modal.maskClosable;
      },
    },
    title: {
      type: String,
    },
    width: {
      type: [Number, String],
      default: 520,
    },
    okText: {
      type: String,
    },
    cancelText: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default() {
        return {};
      },
    },
    className: {
      type: String,
    },
    // for instance
    footerHide: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    transitionNames: {
      type: Array,
      default() {
        return ["ease", "fade"];
      },
    },
    transfer: {
      type: Boolean,
      default() {
        return !this.$IVIEW || this.$IVIEW.transfer === ""
          ? true
          : this.$IVIEW.transfer;
      },
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      eventList: ["on-ok", "on-cancel", "on-visible-change", "input"],
    };
  },
  computed: {
    eventBind() {
      let result = {};
      for (let i = 0; i < this.eventList.length; i++) {
        let e = this.eventList[i];
        result[e] = (a, b) => this.$emit(e, a, b);
      }
      return result;
    },
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>