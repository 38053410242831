const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login')
  },
  // {
  //   path: '/staticMain',
  //   name: 'staticMain',
  //   component: () => import('../views/main'),
  //   children: [
  //     {
  //       path: '/home',
  //       name: 'home',
  //       component: () => import('../views/home'),
  //     },
  //     {
  //       path: '/test',
  //       name: 'test',
  //       component: () => import('../views/test'),
  //     }
  //   ]
  // },
  {
    path: '/informationPerfection',
    name: 'informationPerfection',
    meta: {
      title: '信息完善',
    },
    component: () => import('../views/informationPerfection')
  },
  {
    path: '/cesium',
    name: 'cesium',
    component: () => import('@/components/cesiumMap'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/main'),
    children: [
      {
        path: '/fwsenor',
        name: 'fwsenor',
        component: () => import('@/views/fwsenor'),
      },
    ]
  }
]

export default routes