<template>
  <div class="right-message-box">
    <template v-if="user && info">
      <div class="logo-span">
        <img :src="user.avatar" />
      </div>
      <div class="left-span">
        <div class="time-row">
          {{ time }}
        </div>
        <div
          v-if="info.content && info.content.type === 'image'"
          class="content-box"
        >
          <img
            @click="privew(info)"
            v-if="info.content.scale"
            style="width: 150px; cursor: pointer"
            :src="'data:image/jpeg;base64,' + info.content.scale"
          />
          <img
            @click="privew(info)"
            v-else
            style="width: 150px; cursor: pointer"
            :src="info.content.content"
          />
        </div>
        <div v-else class="content-box">
          {{ info.content.content || info.content }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    user: Object,
    info: Object,
  },
  computed: {
    time() {
      let originTime = this.info?.createTime ?? "";
      if (!originTime) return "";
      let today = moment().format("YYYY-MM-DD");
      let temp = originTime.substring(0, 10);
      if (today == temp) return originTime.substring(11);
      else return originTime;
    },
  },
  methods: {
    privew(info) {
      if (info.content.content) {
        this.$store.dispatch("previewImage", info.content.content);
      }
    },
  },
};
</script>
<style lang='less' scoped>
.right-message-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  .left-span {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;
    .content-box {
      white-space: wrap;
      background: lightblue;
      padding: 8px;
      border-radius: 6px;
      overflow: hidden;
      max-width: 100%;
    }
  }
  .logo-span {
    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      overflow: hidden;
    }
  }
}
</style>