<template>
  <div class="table-page">
    <div class="table-header">
      <slot name="header">
        <div class="header-place">
          <div class="search" v-if="options && options.search">
            <div
              class="search-item"
              v-for="(obj, name) in options.search.rules"
              :key="name"
            >
              <span>{{ obj.name }}：</span>
              <span>
                <Input
                  clearable
                  :style="{ width: obj.width ? obj.width + 'px' : '200px' }"
                  v-model="options.search.model[name]"
                  v-if="obj.type == 'i' || obj.type == 'input'"
                ></Input>
                <Cascader
                  clearable
                  :style="{ width: obj.width ? obj.width + 'px' : '200px' }"
                  v-model="options.search.model[name]"
                  :data="obj.data"
                  v-else-if="obj.type == 'c' || obj.type == 'cascader'"
                >
                </Cascader>
                <Select
                  clearable
                  :style="{ width: obj.width ? obj.width + 'px' : '200px' }"
                  v-model="options.search.model[name]"
                  v-else-if="obj.type == 's' || obj.type == 'select'"
                >
                  <Option
                    v-for="item in obj.data"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </Option>
                </Select>
                <DatePicker
                  clearable
                  :style="{ width: obj.width ? obj.width + 'px' : '200px' }"
                  v-model="options.search.model[name]"
                  v-else-if="obj.type == 'd' || obj.type == 'date'"
                >
                </DatePicker>
              </span>
            </div>
            <div>
              <Button @click="search" type="primary" icon="md-search"
                >查询</Button
              >
            </div>
          </div>
          <div v-if="options && options.add" class="right-option">
            <Button
              @click="add"
              type="primary"
              v-ca="options.add.ca"
              icon="md-add"
            >
              {{ options.add.text }}
            </Button>
          </div>
        </div>
      </slot>
    </div>
    <div ref="tableBox" class="table-box">
      <Table ref="table" v-on="eventsProps" v-bind="nextProps"> </Table>
    </div>
    <div class="table-footer">
      <slot name="footer">
        <div v-if="options && options.page" class="footer-box">
          <Page
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
            :current="options.page.pageNo"
            :page-size="options.page.pageSize"
            :total="options.page.total"
            :size="options.page.size"
          ></Page>
        </div>
      </slot>
    </div>
    <model-modal
      v-if="options && options.form"
      v-model="modelData.show"
      :type="modelData.type"
      :title="modelData.title"
      :options="options.form"
      @on-ok="formSubmit"
    >
      <slot name="form-footer" slot="footer"></slot>
    </model-modal>
  </div>
</template>
<script>
import modelModal from "./model";

export default {
  components: {
    modelModal,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      default() {
        return !this.$IVIEW || this.$IVIEW.size === ""
          ? "default"
          : this.$IVIEW.size;
      },
    },
    width: {
      type: [Number, String],
    },
    height: {
      type: [Number, String],
    },
    // 3.4.0
    maxHeight: {
      type: [Number, String],
    },
    stripe: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    highlightRow: {
      type: Boolean,
      default: false,
    },
    rowClassName: {
      type: Function,
      default() {
        return "";
      },
    },
    context: {
      type: Object,
    },
    noDataText: {
      type: String,
    },
    noFilteredDataText: {
      type: String,
    },
    disabledHover: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    tooltipTheme: {
      default: "dark",
    },
    // 4.5.0
    tooltipMaxWidth: {
      type: Number,
      default: 300,
    },
    // #5380 开启后，:key 强制更新，否则使用 index
    // 4.1 开始支持 String，指定具体字段
    rowKey: {
      type: [Boolean, String],
      default: false,
    },
    // 4.0.0
    spanMethod: {
      type: Function,
    },
    // 4.0.0
    showSummary: {
      type: Boolean,
      default: false,
    },
    // 4.0.0
    summaryMethod: {
      type: Function,
    },
    // 4.0.0
    sumText: {
      type: String,
    },
    // 4.1.0
    indentSize: {
      type: Number,
      default: 16,
    },
    // 4.1.0
    loadData: {
      type: Function,
    },
    // 4.4.0
    updateShowChildren: {
      type: Boolean,
      default: false,
    },
    // 4.1.0
    contextMenu: {
      type: Boolean,
      default: false,
    },
    // 4.2.0
    showContextMenu: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 传递给table的props
      nextProps: {},
      // 传递给Table的时间处理
      eventList: [
        "on-current-change",
        "on-select",
        "on-select-cancel",
        "on-select-all",
        "on-select-all-cancel",
        "on-select-change",
        "on-sort-change",
        "on-filter-change",
        "on-row-click",
        "on-row-dblclick",
        "on-cell-click",
        "on-expand",
        "on-drag-drop",
        "on-column-width-resize",
        "on-contextmenu",
        "on-expand-tree",
      ],
      // 数据弹窗model
      modelData: {
        show: false,
        title: "",
        type: "",
      },
    };
  },
  computed: {
    eventsProps() {
      let result = {};
      for (let i = 0; i < this.eventList.length; i++) {
        let name = this.eventList[i];
        result[name] = (a, b, c, d) => {
          this.doEvent(name, a, b, c, d);
        };
      }
      return result;
    },
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.setNextProps();
      },
    },
  },
  methods: {
    // 点击查询
    search() {
      this.$emit("search");
    },
    // 分页改变
    pageChange(pageNo) {
      if (this.options?.page?.onChange) this.options.page.onChange(pageNo);
      this.$emit("page-change", pageNo);
    },
    // 分页size改变
    pageSizeChange(pageSize) {
      if (!this.options?.page?.onPageSizeChange)
        this.options.page.onPageSizeChange(pageSize);
      this.$emit("on-page-size-change", pageSize);
    },
    // 添加
    add() {
      if (this.options?.add?.callback) {
        this.options.add.callback();
        return;
      }
      if (this.options?.add?.beforeAdd) {
        this.options.add.beforeAdd();
      }
      this.modelData.title = this.options?.add?.text ?? "新增";
      this.modelData.type = "add";
      this.modelData.show = true;
    },
    check() {
      this.modelData.title = this.options?.check?.text ?? "查看";
      this.modelData.type = "check";
      this.modelData.show = true;
    },
    closeModal() {
      this.modelData.show = false;
    },
    formSubmit() {
      if (this.modelData.type == "add") {
        if (this.options?.add?.submit) {
          new Promise((resolve) => {
            this.options.add.submit(resolve);
          }).then((res) => {
            if (res === false) return;
            this.modelData.show = false;
          });
        }
      }
    },
    exportCsv(options) {
      this.$refs.table.exportCsv(options);
    },
    clearCurrentRow() {
      this.$refs.table.clearCurrentRow();
    },
    selectAll(options) {
      this.$refs.table.selectAll(options);
    },
    doEvent(name, a, b, c, d) {
      this.$emit(name, a, b, c, d);
    },
    // 组合传给子组件的props
    setNextProps() {
      let nextProps = {};
      for (let key in this.$props) {
        if (this.$props[key] === undefined) continue;
        nextProps[key] = this.$props[key];
      }
      let height = Math.floor(this.$refs.tableBox.clientHeight);
      nextProps.height = height;
      this.nextProps = nextProps;
    },
  },
  mounted() {
    this.setNextProps();
    window.addEventListener("resize", this.setNextProps);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setNextProps);
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>