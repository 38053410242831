import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/common.less"
import 'view-design/dist/styles/iview.css'
import ViewUI from 'view-design'
import dataV from '@jiaminghi/data-view'
import { post, get, download ,downloads} from "./common/http/index"
import API from "./common/http/api"
// import '../public/js/DrawingManager'
// import '../public/lushu/index'
import "mapbox-gl/dist/mapbox-gl.css"
// import './assets/css/my-theme/green.less'
// import './assets/css/my-theme/blue.less'
import './assets/css/my-theme/index.less'
// import './assets/css/my-theme/purple.less'
import '@/assets/iconfont/iconfont.css'
import hTable from '@/components/hTable'
import uploadImage from '@/components/upload/upload_image'
import hModal from '@/components/hModal'
import xTable from "@/components/xTable"
// import ws from "./store/socket"
import './assets/js/ezuikit';
//video.js样式文件
import 'video.js/dist/video-js.css'

Vue.config.productionTip = false

Vue.use(ViewUI)
Vue.use(dataV)
Vue.component('h-table', hTable)
Vue.component('h-modal', hModal)
Vue.component('upload-image', uploadImage)
Vue.component('x-table', xTable)

const CA = function (Vue) {
  Vue.mixin({
    computed: {
      CA () {
        return authority => this.$store.state.permission.permission.has(authority);
      },
      getImgUrl () {
        return (servicePath, url) => {
          if (!url) return ''
          if (!servicePath) return url
          if (url.indexOf('http') > -1) {
            return url
          } else {
            return servicePath + url
          }
        }
      }
    }
  })
}
Vue.use(CA);

// 注册自定义指令v-c
Vue.directive('ca', {
  inserted (el, binding, vnode) {
    let value = binding.value;
    if (!value) return;
    let allowed = store.state.permission.permission.has(value)
    if (!allowed) el.parentNode.removeChild(el)
  }
})

Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$api = API
Vue.prototype.$download = download
Vue.prototype.$downloads = downloads
// Vue.prototype.$ws = ws;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

!function (n) {
  var e = n.document,
    t = e.documentElement,
    i = 1920,
    d = i / 100,
    o = "orientationchange" in n ? "orientationchange" : "resize",
    a = function () {
      var n = t.clientWidth || 1320; n > i && (n = i);
      t.style.fontSize = n / d + "px"
    };
  e.addEventListener && (n.addEventListener(o, a, !1), e.addEventListener("DOMContentLoaded", a, !1));
}(window);
