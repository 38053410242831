var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"class-name":"talk-modal-special-class","width":820,"draggable":"","footer-hide":"","value":_vm.value},on:{"input":_vm.modalShowCallback}},[_c('div',{staticClass:"talk-container"},[_c('div',{staticClass:"talk-list"},[_c('scroll-box',{attrs:{"showButton":false}},_vm._l((_vm.talkList),function(talk){return _c('div',{key:talk.userId,class:[
            'talk-item',
            _vm.currentTalk && _vm.currentTalk.userId == talk.userId
              ? 'current-talk'
              : '',
          ],on:{"click":function($event){return _vm.changeTalk(talk)}}},[_c('span',{staticClass:"user-logo"},[_c('img',{attrs:{"src":talk.avatar}})]),_c('span',{staticClass:"user-name"},[_vm._v(" "+_vm._s(talk.userName)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(talk.unreadNum),expression:"talk.unreadNum"}],staticClass:"unread-num"},[_vm._v(" "+_vm._s(talk.unreadNum)+" ")])])}),0)],1),_c('div',{staticClass:"talk-info"},[(_vm.currentTalk)?_c('div',{staticClass:"talk-user-info"},[_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.currentTalk.userName))]),_vm._l((_vm.currentTalk.roleIdName),function(tag){return _c('Tag',{key:tag},[_vm._v(" "+_vm._s(tag)+" ")])})],2):_vm._e(),_c('div',{staticClass:"info-list-box"},[_c('scroll-box',{ref:"infoScroll",attrs:{"align-direction":"bottom","innerStyle":{
            minHeight: '100%',
          },"show-button":false}},[_vm._l((_vm.currentTalkInfo),function(info){return [(info.cUserId == _vm.currentTalk.userId)?_c('left-message',{key:info.id,attrs:{"info":info,"user":_vm.currentTalk}}):_c('right-message',{key:info.id,attrs:{"info":info,"user":_vm.user}})]})],2)],1),_c('div',{staticClass:"type-box"},[_c('Input',{staticClass:"talk-modal-textarea",staticStyle:{"width":"100%","height":"100%"},attrs:{"rows":4,"border":false,"type":"textarea"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('div',{staticClass:"button-span"},[_c('span',{staticClass:"img-icon iconfont",attrs:{"title":"发送图片"},on:{"click":_vm.choseImg}},[_vm._v("")]),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.sendTextMessage}},[_vm._v("发送")])],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }