export default {
  state: {
    //系统消息数组
    sysMsgArr: [],
    //系统消息
    sysMsg: null
  },

  mutations: {
    setSysMsg(state, msg) {
      state.sysMsgArr = [msg, ...state.sysMsgArr]
      state.sysMsg = msg
    }
  },

  actions: {
    //处理系统消息
    sysMsgDeal({commit}, msg) {
      console.log(msg)
      commit('setSysMsg', msg)
    },
  },

  modules: {}
}