<template>
  <div class="uploadContainer">
    <slot v-bind:uploadList="uploadList">
      <div class="upload-list" :key="index" v-for="(item, index) in uploadList">
        <template v-if="item.status === 'finished'">
          <p>
            {{ item.name }}
            <Icon type="md-close" color="red" />
          </p>
        </template>
        <template v-else>
          <Progress
            v-if="item.showProgress"
            :percent="item.percentage"
            hide-info
          ></Progress>
        </template>
      </div>
    </slot>
    <Upload
      ref="upload"
      :show-upload-list="false"
      :default-file-list="defaultList"
      :on-success="handleSuccess"
      :format="format"
      :max-size="maxSize ? maxSize : null"
      :on-progress="handleProgress"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :on-error="handleRrror"
      :before-upload="handleBeforeUpload"
      :accept="accept"
      :action="url"
      :headers="headers"
      :data="datas"
      style="display: inline-block; width: fit-content"
    >
      <div v-if="!max || uploadList.length < max">
        <slot name="button">
          <Button>点击上传</Button>
        </slot>
      </div>
    </Upload>
    <picture-view :src="src" v-model="visible"></picture-view>
  </div>
</template>
<script>
import API, { V1 } from "@/common/http/api";
import pictureView from "../picture_view";

export default {
  components: {
    pictureView,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    maxSize: Number,
    max: Number,
    accept: { type: String, default: null },
    format: {
      type: Array,
      default() {
        return [];
      },
    },
    url: {
      type: String,
      defualt: V1 + API.UPLOAD.FILE,
    },
    beforeUpload: Function,
    onProgress: Function,
    onSuccess: Function,
    onError: Function,
    onFormatError: Function,
    onExceededSize: Function,
    otherData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      defaultList: [],
      datas: {
        ...this.otherData,
        userId: localStorage.userId,
        companyNo: localStorage.companyNo,
      },
      headers: {
        Authorization: "Bearer_" + localStorage.token,
      },
      src: "",
      visible: false,
      uploadList: [],
    };
  },
  methods: {
    clearFiles() {
      this.$refs.upload.clearFiles();
      this.$nextTick(() => {
        this.uploadList = this.$refs.upload.fileList;
      });
    },
    handleView(url) {
      this.src = url;
      this.visible = true;
    },
    handleRemove(file) {
      const fileList = this.$refs.upload.fileList;
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
      this.$nextTick(() => {
        this.$emit("input", this.uploadList.map((item) => item.url).join(","));
      });
    },
    handleSuccess(res, file, fileList) {
      if (this.onSuccess) {
        this.onSuccess(res, file, fileList);
      }
      if (+res.code === 200) {
        file.url = res.data.filePath;
        let lastIndex = res.data.fileName.lastIndexOf("/");
        file.name = res.data.fileName.substring(lastIndex + 1);
        this.$nextTick(() => {
          this.$emit(
            "input",
            this.uploadList
              .map((item) => {
                // if (
                //   item.response &&
                //   item.response.data &&
                //   item.response.data.fileName
                // ) {
                //   return item.response.data.fileName;
                // }
                return item.url;
              })
              .join(",")
          );
        });
      } else {
        this.$Message.error("上传失败");
        this.$nextTick(() => {
          let index = this.$refs.upload.fileList.indexOf(file);
          if (index !== -1) this.$refs.upload.fileList.splice(index);
        });
      }
    },
    handleFormatError(file, fileList) {
      if (this.onFormatError) {
        this.onFormatError(file, fileList);
      }
      //   this.$Message.warning("请选择图片上传!");
    },
    handleMaxSize() {
      this.$Message.warning(`文件大小不要超过${this.maxSize}kb`);
    },
    handleRrror(error, file, fileList) {
      if (this.onError) {
        this.onError(error, file, fileList);
      }
    },
    handleBeforeUpload(file, fileList) {
      if (this.max) {
        let check = this.uploadList.length < this.max;
        if (!check) {
          this.$Message.warning({
            title: "最多上传" + this.max + "份文件",
          });
          return false;
        }
      }
      if (this.beforeUpload) {
        return this.beforeUpload(file, fileList);
      }
      return true;
    },
    handleProgress(event, file, fileList) {
      if (this.onProgress) {
        this.onProgress(event, file, fileList);
      }
    },
    setDefault() {
      let defaultList = this.value.split(",").map((item) => {
        let last_index = item.lastIndexOf("/");
        let name = item.substring(last_index + 1);
        return {
          url: item,
          name,
          status: "finished",
          response: {
            data: {
              filePath: item,
              fileName: name,
            },
          },
        };
      });
      this.uploadList.push(...defaultList);
    },
  },
  created() {
    if (this.value) {
      this.setDefault();
    }
  },
  watch: {
    value(val) {
      if (val && val !== this.uploadList.map((item) => item.url).join(",")) {
        this.setDefault();
      }
    },
  },
  mounted() {
    this.uploadList = this.$refs.upload.fileList;
  },
};
</script>
<style lang="less" scoped>
.uploadContainer {
  display: flex;
  max-width: 100%;
}
.upload-list {
  width: 100%;
  background: #cccccc;
}
</style>
<style lang='less'>
.ivu-form-item-error {
  .uploadContainer {
    .ivu-upload {
      box-shadow: 0 0 4px 0 #ff8383;
    }
  }
}
</style>
