import axios from "axios";
import API, { baseUrl } from "./api"
import { LoadingBar, Message } from "view-design";

let instance = axios.create({
  baseURL: baseUrl.URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  timeout: 30000
})

instance.interceptors.request.use(config => {
  if (!config.data) {
    config.data = {};
  }
  if (!config.data.userId && localStorage.userId) {
    config.data.userId = localStorage.userId
  }
  if (!config.data.companyNo && localStorage.companyNo) {
    config.data.companyNo = localStorage.companyNo
  }
  for (let key in config.data) {
    if (config.data[key] === '') {
      delete config.data[key];
    }
  }
  localStorage.token && (config.headers.common['Authorization'] = `Bearer_${localStorage.token}`);
  LoadingBar.start();
  return config;
})

instance.interceptors.response.use(response => {
  LoadingBar.finish();
  if (response.config.url == API.USER.LOGIN) {
    return response.data;
  }
  if (response.data.code == 200) {
    return response.data.data;
  }
  if (response.data) {
    return response;
  }
  Message.error({ content: response.data?.message ?? '请求好像出错了，请重新尝试', duration: 1.5 });
  return Promise.reject(response.data)
}, e => {
  LoadingBar.error();
  return Promise.reject(e);
})

export const post = instance.post;
export const get =instance.get



//导出方法
export function download (url, data, fileName) {
  instance({
    url: url,
    method: 'post',
    data: data,
    responseType: 'blob'
  }).then(res => {
    let lists = res.data
    var blob = new Blob([lists], { type: res.headers['content-type'] })
    if ('download' in document.createElement('a')) {
      //非IE下载
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) //创建下载的链接
      downloadElement.href = href
      downloadElement.download = fileName ? (fileName + '.xlsx') : '导出文件.xlsx' //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    } else {
      //IE 10+ 下载
      navigator.msSaveBlob(blob, (fileName ? (fileName + '.xlsx') : '导出文件.xlsx'))
    }
  })
}
//导出方法
export function downloads (url, fileName) {
  instance({
    url: url,
    method: 'get',
    responseType: 'blob'
  }).then(res => {
    let lists = res.data
    var blob = new Blob([lists], { type: res.headers['content-type'] })
    if ('download' in document.createElement('a')) {
      //非IE下载
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) //创建下载的链接
      downloadElement.href = href
      downloadElement.download = fileName ? (fileName + '.docx') : '导出文件.docx' //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    } else {
      //IE 10+ 下载
      navigator.msSaveBlob(blob, (fileName ? (fileName + '.docx') : '导出文件.docx'))
    }
  })
}