import routes from "@/router/router"
import { post } from "@/common/http"
import API from "@/common/http/api"
import CU from "@/common/util"
import router from '@/router'

export default {
  state: {
    routers: routes,
    addRouters: {},
    permission: new Set(),
    permissionReady: false,
    menuList: {},
    firstLevelMenu: [],
    currentRole: null,
  },
  mutations: {
    setPermission(state, permission) {
      state.permissionReady = true
      state.permission = new Set(permission)
    },
    setRouters(state, result) {
      state.addRouters = result.routers
      router.addRoute(result.routers)
      router.addRoute(result.routers2)
      router.addRoute({
        path: '/*',
        redirect: { name: 'login' }
      })
      state.routers = routes.concat(result.routers, result.routers2)
    },
    setFirstLevelMenus(state, menus) {
      state.firstLevelMenu = menus
    },
    setMenuList(state, menus) {
      state.menuList = menus
    },
    setCurrentRole(state, role) {
      state.currentRole = role
    },
    clearMenus(state) {
      state.permissionReady = false
      state.permission = []
      state.addRouters = {}
      state.routers = routes
      state.menuList = {}
      state.currentRole = null
    }
  },
  actions: {
    getPermission({ commit }) {
      return new Promise((resolve, reject) => {
        post(API.MENU.AUTHORITY_MENU, {
          menuType: "wfpt"
        }).then(res => {
          if (!Array.isArray(res) || res.length == 0) return reject()
          commit('setFirstLevelMenus', res)
          commit('setMenuList', res[0])
          // commit('setCurrentRole', res[0])
          // let data = res.reduce((total, item) => {
          //    total.push(...item.treeMenu)
          //    return total
          // }, [])
          let result = CU.filterAsyncRouter(res)
          commit('setPermission', result.permission)
          commit('setRouters', result)
          resolve()
        }).catch((e) => {
          console.log(e)
          reject()
        })
      })
    },
    clearPermission({ commit }) {
      return new Promise((resolve, reject) => {
        commit('clearMenus')
        resolve()
      })
    }
  },
  modules: {},
}