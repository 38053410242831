<template>
  <Modal
    class-name="talk-modal-special-class"
    :width="820"
    draggable
    footer-hide
    :value="value"
    @input="modalShowCallback"
  >
    <div class="talk-container">
      <div class="talk-list">
        <scroll-box :showButton="false">
          <div
            :class="[
              'talk-item',
              currentTalk && currentTalk.userId == talk.userId
                ? 'current-talk'
                : '',
            ]"
            v-for="talk in talkList"
            :key="talk.userId"
            @click="changeTalk(talk)"
          >
            <span class="user-logo">
              <img :src="talk.avatar" />
            </span>
            <span class="user-name">
              {{ talk.userName }}
            </span>
            <span class="unread-num" v-show="talk.unreadNum">
              {{ talk.unreadNum }}
            </span>
          </div>
        </scroll-box>
      </div>
      <div class="talk-info">
        <div v-if="currentTalk" class="talk-user-info">
          <span class="user-name">{{ currentTalk.userName }}</span>
          <Tag v-for="tag in currentTalk.roleIdName" :key="tag">
            {{ tag }}
          </Tag>
        </div>
        <div class="info-list-box">
          <scroll-box
            ref="infoScroll"
            align-direction="bottom"
            :innerStyle="{
              minHeight: '100%',
            }"
            :show-button="false"
          >
            <template v-for="info in currentTalkInfo">
              <left-message
                :info="info"
                :user="currentTalk"
                v-if="info.cUserId == currentTalk.userId"
                :key="info.id"
              ></left-message>
              <right-message
                :info="info"
                :user="user"
                v-else
                :key="info.id"
              ></right-message>
            </template>
          </scroll-box>
        </div>
        <div class="type-box">
          <Input
            v-model="message"
            class="talk-modal-textarea"
            :rows="4"
            :border="false"
            type="textarea"
            style="width: 100%; height: 100%"
          >
          </Input>
          <div class="button-span">
            <span @click="choseImg" title="发送图片" class="img-icon iconfont"
              >&#xe6e7;</span
            >
            <Button @click="sendTextMessage" type="primary">发送</Button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { mapState } from "vuex";
import scrollBox from "@/components/scrollBox";
import rightMessage from "./rightMessage.vue";
import leftMessage from "./leftMessage.vue";
import axios from "axios";
import API, { V1 } from "@/common/http/api";

export default {
  components: {
    scrollBox,
    rightMessage,
    leftMessage,
  },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    ...mapState({
      value: (state) => state.rongIMLib.show,
      user: (state) => state.user.user,
      talkList: (state) => state.rongIMLib.talkList,
      currentTalk: (state) => {
        if (!state.rongIMLib.currentTalk) return null;
        let talk = { ...state.rongIMLib.currentTalk };
        talk.roleIdName = talk.roleName.split(",");
        talk.roleId = talk.roleId.split(",");
        return talk;
      },
      currentTalkInfo: (state) => state.rongIMLib.currentTalkInfo,
      historyPage: (state) => state.rongIMLib.historyPage,
    }),
  },
  methods: {
    // 发送图片消息
    choseImg() {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute(
        "accept",
        "image/gif,image/jpeg,image/jpg,image/png,image/svg"
      );
      input.addEventListener("change", () => {
        let files = input.files;
        if (files.length > 0) {
          let file = files[0];
          this.shortening(file).then((base64) => {
            this.uploadImage(file).then((url) => {
              this.$store.dispatch("sendImageMessage", {
                targetId: this.currentTalk.userId + "",
                userId: localStorage.getItem("userId"),
                base64,
                url,
              });
            });
          });
        }
      });
      input.click();
    },
    // 上传图片
    uploadImage(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("folder", "images");
        formData.append("userId", localStorage.userId);
        formData.append("companyNo", localStorage.companyNo);
        formData.append("file", file);
        axios
          .post(V1 + API.UPLOAD.FILE, formData, {
            headers: {
              Authorization: `Bearer_${localStorage.token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status === 200 && res.data.code === 200) {
              let url = res.data.data.filePath;
              resolve(url);
            } else {
              reject();
            }
          });
      });
    },
    // 缩略图
    shortening(file) {
      return new Promise((resolve, reject) => {
        try {
          let flag = file.size > 80 * 1024;
          let size = file.size;
          // let scale =
          //   file.size > 80 * 1024
          //     ? Math.floor(1 / (file.size / (80 * 1024)))
          //     : 1;
          let reader = new FileReader();
          reader.onload = function (e) {
            let url = reader.result;
            let img = document.createElement("img");
            img.crossOrigin = "anonymous";
            img.onload = function () {
              let canvas = document.createElement("canvas");
              let width = img.naturalWidth;
              let height = img.naturalHeight;
              if (flag) {
                let newWidth = Math.ceil((width * 80 * 1024) / size);
                let newHeight = Math.ceil((height * 80 * 1024) / size);
                canvas.width = newWidth;
                canvas.height = newHeight;
                let context2d = canvas.getContext("2d");
                context2d.drawImage(
                  img,
                  0,
                  0,
                  width,
                  height,
                  0,
                  0,
                  newWidth,
                  newHeight
                );
              } else {
                canvas.width = width;
                canvas.height = height;
                let context2d = canvas.getContext("2d");
                context2d.drawImage(
                  img,
                  0,
                  0,
                  width,
                  height,
                  0,
                  0,
                  width,
                  height
                );
              }
              let base64 = canvas.toDataURL("image/jpeg", 0.6);
              let index = base64.indexOf(",") + 1;
              resolve(base64.substring(index));
            };
            img.src = url;
          };
          reader.readAsDataURL(file);
        } catch (e) {
          console.log(e);
          reject(e);
        }
      });
    },
    //   发送文本消息
    sendTextMessage() {
      if (!this.message) return;
      if (!this.currentTalk) return;
      this.$store
        .dispatch("sendTextMessage", {
          targetId: this.currentTalk.userId + "",
          userId: localStorage.getItem("userId"),
          content: this.message.trim(),
        })
        .then(() => {
          this.message = "";
        });
    },
    // 更改会话
    changeTalk(talk) {
      this.$store.dispatch("changeTalk", talk);
    },
    // 弹窗显示状态回调
    modalShowCallback(val) {
      if (!val) this.$store.dispatch("closeTalkModal");
    },
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
<style>
.talk-modal-special-class .ivu-modal-body {
  padding: 0px !important;
}
.talk-modal-textarea textarea {
  resize: none !important;
}
</style>
