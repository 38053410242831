import { post } from '@/common/http';
import API from '@/common/http/api';
export default {
  state: {
    farms: [],
    sites: [],
    // 基地点是否准备好
    farmReady: false,
    // 该账户下所有基地缓存
    farmList: [],
    // 传感器/设备点数据缓存
    farmSites: new Map(),
    // 基地范围集合
    farmArea: new Map(),
    // 基地处理设备集合
    farmDevice: new Map(),
    // 基地处理设施集合
    farmDealDevice: new Map()
  },
  mutations: {
    setFarms(state, farms) {
      state.farms = farms
    },
    setSites(state, sites) {
      state.sites = sites
    },
    setFarmList(state, list) {
      state.farmList = list;
      state.farmReady = true
    },
    clearFarmList(state) {
      state.farmList = []
      state.farmReady = false
    },
    // 添加基地设备点缓存
    addFarmSites(state, { farmId, sites }) {
      state.farmSites.set(farmId, sites)
    },
    clearFarmSites(state) {
      state.farmSites.clear()
    },
    // 添加基地范围
    addFarmArea(state, { farmId, areaList }) {
      state.farmArea.set(farmId, areaList)
    },
    clearFarmArea(areaList) {
      areaList.farmArea.clear()
    },
    // 添加处理设备
    addFarmDevice(state, { farmId, list }) {
      state.farmDevice.set(farmId, list)
    },
    clearFarmDevice(state) {
      state.farmDevice.clear()
    },
    // 添加处理设施
    addFarmDealDevice(state, { farmId, list }) {
      state.farmDealDevice.set(farmId, list)
    },
    clearFarmDealDevice(state) {
      state.farmDealDevice.clear()
    }
  },
  actions: {
    // 查询所有基地
    getAllFarms({ commit }) {
      return new Promise(resolve => {
        post(API.MAP.MAP_CFG_INFO,).then(res => {
          commit('setFarmList', res.list)
          resolve(res.list)
        })
      })
    },
    // 查询所有
    getFarms({ commit }, params = {}) {
      post(API.MAP.MAP_CFG_INFO, {
        ...params
      }).then(res => {
        commit('setFarms', res.list)
      })
    },
    getSites({ commit }, params = {}) {
      return new Promise(resolve => {
        post(API.DEVICE_LL.AUTHORIZATION_LIST, {
          ...params
        }).then(res => {
          // console.log(res)
          commit('setSites', res.list)
          resolve(res.list)
        })
      })
    },
    // 查询基地设备点
    getFarmSites({ commit, state }, farmId) {
      return new Promise(resolve => {
        if (state.farmSites.get(farmId)) {
          return resolve(state.farmSites.get(farmId))
        }
        post(API.DEVICE_LL.AUTHORIZATION_LIST, {
          farmId,
          vestingNo: localStorage.getItem('companyNo')
        }).then(res => {
          commit('addFarmSites', { farmId, sites: res.list })
          resolve(res.list)
        })
      })
    },
    // 获取基地处理设备
    getFarmDevice({ commit, state }, farmId) {
      return new Promise(resolve => {
        if (state.farmDevice.get(farmId)) {
          return resolve(state.farmDevice.get(farmId))
        }
        post(API.DEAL_DEVICE.LIST, {
          farmId
        }).then(res => {
          commit('addFarmDevice', { farmId, list: res.list })
          resolve(res.list)
        })
      })
    },
    // 获取基地处理设施
    getFarmDealDevice({ commit, state }, farmId) {
      return new Promise(resolve => {
        if (state.farmDealDevice.get(farmId)) {
          return resolve(state.farmDealDevice.get(farmId))
        }
        post(API.DEAL_FACILITIES.LIST, {
          farmId
        }).then(res => {
          commit('addFarmDealDevice', { farmId, list: res.list })
          resolve(res.list)
        })
      })
    },
    // 获取基地范围
    getFarmArea({ commit, state }, farmId) {
      return new Promise(resolve => {
        if (state.farmArea.get(farmId)) {
          return resolve(state.farmArea.get(farmId))
        }
        let farmStatus = false, sceneStatus = false;
        let areaList = [];
        let callback = () => {
          if (farmStatus && sceneStatus) {
            commit('addFarmArea', {
              farmId,
              areaList
            })
            resolve(areaList)
          }
        }
        post(API.BASE_MANAGE.VIEW, {
          id: farmId
        }).then(res => {
          areaList.unshift(res)
          farmStatus = true;
          callback()
        })
        post(API.PRODUCTION_AREA.LIST, {
          farmId
        }).then(res => {
          areaList.push(...res.list)
          sceneStatus = true;
          callback()
        })
      })
    },
    // 清理缓存
    clearMap({ commit }) {
      commit('clearFarmList')
      commit('clearFarmSites')
      commit('clearFarmArea')
      commit('clearFarmDevice')
      commit('clearFarmDealDevice')
    }
  },
  modules: {},
}