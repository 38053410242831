<template>
  <Modal
    :footer-hide="true"
    class="picture-view-components-style"
    :closable="false"
    :value="value"
    @input="(val)=>$emit('input',val)"
    :transition-names="['picture-view','fade']"
  >
    <img style="max-width:80vw;max-height:80vh" :src="src" />
  </Modal>
</template>
<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean
    },
    src: {
      default: "",
      type: String
    }
  }
};
</script>
<style>
.picture-view-components-style .ivu-modal {
  width: fit-content !important;
  display: flex !important;
}
.picture-view-components-style .picture-view-leave-active {
  transition: all 0.3s ease;
}
.picture-view-components-style .picture-view-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>
